import { Component, OnInit } from '@angular/core';
import { Device, HashedDeviceToken, UserDetails, RealmService } from '../../realm.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RemoveUserComponent } from '../remove/remove.component';
import { PromoteUserComponent } from '../promote/promote.component';
import { RegenerateCodeUserComponent } from '../regen_code/regen_code.component';
import { RemoveDeviceComponent } from '../remove_device/remove_device.component';
import { RemoveDeviceTokenComponent } from '../remove_device_token/remove_device_token.component';
import { UserEventLogsComponent } from '../user_event_logs/event_logs.component';
import { AccountRecoveryComponent } from '../account_recovery/account_recovery.component';

// Details about a single realm user.
@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

	public user$: Observable<UserDetails>;
	public hasActiveDevices = false;
	constructor(
		private realmService: RealmService,
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog
	) { }

	ngOnInit() {
		const id = this.route.snapshot.paramMap.get('userId');
		if (id) {
			this.user$ = this.realmService.user(id);
			this.user$.subscribe((user: UserDetails) => {
				this.hasActiveDevices = user.devices.filter((dev: Device) => !dev.removed ).length > 0;
			},
			(error) => {
				this.hasActiveDevices = false;
			});
		}
	}

	// Show a dialog for removing the user
	public async removeUser(userToRemove: UserDetails) {
		const dialogRef = this.dialog.open(RemoveUserComponent, {
			data: { user: userToRemove }
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result.removed) {
				this.router.navigate(['../'], { relativeTo: this.route });
			}
		});
	}

	public async promoteUser(userToPromote: UserDetails) {
		const dialogRef = this.dialog.open(PromoteUserComponent, {
			data: { user: userToPromote }
		});
		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.ngOnInit();
			}
		});
	}

	public async removeDevice(user: UserDetails, device: Device) {
		const dialogRef = this.dialog.open(RemoveDeviceComponent, {
			data: { user: user, device: device }
		});
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}

	public async removeDeviceToken(user: UserDetails, token: HashedDeviceToken) {
		const dialogRef = this.dialog.open(RemoveDeviceTokenComponent, {
			data: { user: user, token: token }
		});
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}

	public async regenRegCode(user: UserDetails) {
		const dialogRef = this.dialog.open(RegenerateCodeUserComponent, {
			data: { user: user }
		});
		dialogRef.afterClosed().subscribe((result: boolean) => {
			if(result) {
				this.ngOnInit();
			}
		});
	}

	public openEventLogs(user: UserDetails, device: Device) {
		const dialogRef = this.dialog.open(UserEventLogsComponent, {
			data: {
				user: user,
				device: device
			},
			autoFocus: false
		});
		
		dialogRef.afterClosed();
	}

	public isNotConnected(device: Device) {
		return device.connection_state != "Connected";
	}

	public async generateDeviceInvitation(user: UserDetails) {
		const dialogRef = this.dialog.open(AccountRecoveryComponent, {
			data: { user: user }
		});
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}
}