import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/** A class that handles authentication to the api */
@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	constructor(
		private snackBar: MatSnackBar,
	) { }

	open(message: string): void {
		this.snackBar.open(
			message,
			'dismiss',
			{
				duration: 5000,
				verticalPosition: 'top'
			}
		);
	}
}
