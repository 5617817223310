import { Component, OnInit } from '@angular/core';
import { PodService } from '../pod.service';
import { RealmService } from '../../realm/realm.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as Converter from 'ansi-to-html';

// The console log of a pod in Kubernetes
@Component({
	selector: 'app-console-logs',
	templateUrl: './console_logs.component.html',
	styleUrls: ['./console_logs.component.scss']
})
export class ConsoleLogsComponent implements OnInit {
	private converter = new Converter({newline: true});
	public logs$: Observable<string>;
	constructor(
		private podService: PodService,
		private realmService: RealmService
	) { }

	ngOnInit() {
		if (this.realmService.realmIdFromPath$.value) {
			this.refresh();
		}
	}

	public refresh() {
		this.logs$ = this.podService.consoleLogs()
			.pipe(map((logAnsi) => {
				return this.converter.toHtml(logAnsi);
			}), catchError((err, obj) => {
				return of('Failed to fetch log.' + err);
			}));
	}
}
