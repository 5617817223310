import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoggedInAdmin, AuthService } from '../auth/auth.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class LandingGuard implements CanActivate {

	account$: Observable<LoggedInAdmin> = this.authService.account;

	constructor(
		private authService: AuthService,
		private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		return this.account$.pipe(
			map((loggedInAdmin: LoggedInAdmin) => {
				if (next.queryParams.token) {
					// If we find an admin Invite token, go to the join component
					this.router.navigate(['/join'], {queryParams: next.queryParams});
				} else if (loggedInAdmin.realms.default) {
					// Route to our default realm
					this.router.navigate(['/realms', loggedInAdmin.realms.default]);
				} else if (loggedInAdmin.realms.count === 0) {
					// We don't have a realm. Go and create one after completed payment.
					this.router.navigate(['/realms', 'purchase']);
				} else {
					// We have multiple realms, show the overview page.
					this.router.navigate(['/realms']);
				}

				// always return false as we just redirect to an other route
				return false;
			}),
			catchError(() => {
				this.router.navigate(['/realms']);
				return of(false);
			}),
		)
	}
}
