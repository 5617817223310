import { Component, Inject } from '@angular/core';
import { RealmService, UserDetails } from '../../realm.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-invite',
	templateUrl: './promote.component.html',
	styleUrls: ['./promote.component.scss']
})
export class PromoteUserComponent {
	public errorMessage: string;
	public isBusy = false;
	public user: UserDetails;

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<PromoteUserComponent>, @Inject(MAT_DIALOG_DATA) private data: any)
	{
		this.user = data.user;
	}

	async submit() {
		if (this.errorMessage) // error condition so just close.
			this.dialogRef.close(false);
		
		try {
			this.isBusy = true;
			await this.realmService.promoteUser(this.user.id);
			this.isBusy = false;
			this.dialogRef.close(true);
		} catch (e) {
			this.isBusy = false;
			this.errorMessage = e.error;
		}
	}

	close() {
		this.dialogRef.close(false);
	}
}
