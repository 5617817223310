import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'message-box',
	templateUrl: './message_box.component.html',
	styleUrls: ['./message_box.component.scss']
})
export class MessageBoxComponent {
	public title: string;
	public message: string;
	public isError = false;

	constructor(
		public dialogRef: MatDialogRef<MessageBoxComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
		this.title = data.title;
		this.message = data.message;
		this.isError = (data.isError) ? true : false;
	}

	close() {
		this.dialogRef.close();
	}
}
