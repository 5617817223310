import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RealmService, RealmListItem } from '../realm.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ListComponent {

	realms$: Observable<Array<RealmListItem>> = this.realmService.realms();

	constructor(
		private realmService: RealmService,
		private router: Router) { }

	public selectRealm(item: RealmListItem) {
		this.router.navigate(['realms', item.id]);
	}

	public redirectToCreate() {
		return 'purchase';
	}
}
