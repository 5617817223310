import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
	constructor(
		private gtmService: GoogleTagManagerService,
		private router: Router,
	) { }

	ngOnInit() {
		this.router.events.forEach(item => {
			if (item instanceof NavigationEnd && environment.production) {
				this.gtmService.pushTag({
					event: 'page',
					pageName: item.url
				});
			}
		});
	}
}
