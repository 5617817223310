import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PodItem, PodService, PodType } from '../../pods/pod.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

	podItem$: BehaviorSubject<PodItem> = new BehaviorSubject<PodItem>(undefined);

	constructor(
		private podService: PodService) {}

	ngOnInit(): void {
		try {
			this.podService.pods().subscribe(pods => {
				if (pods === undefined) {
					// todo
				} else {
					const pod = pods.find(p => {
						return p.type === PodType.Storage;
					});
					if (pod) {
						this.podItem$.next(pod);
					}
				}
			});
		} catch (err) {}
	}
}
