import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { RealmService } from './realm.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class HasRealmGuard implements CanActivate {

	constructor(
		private realmService: RealmService,
		private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.realmService.realms().pipe(
			map(realms => realms.length > 0),
			tap(hasRealm => {
				if(!hasRealm) {
					this.router.navigate(['realms', 'purchase']);
				}
			})
		);
	}
}
