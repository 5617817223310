import { Component, OnInit } from '@angular/core';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, empty } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PodItem, PodService } from './pod.service';

/** List Pods in a Realm */
@Component({
	selector: 'app-pods',
	templateUrl: './pods.component.html',
	styleUrls: ['./pods.component.scss']
})
export class PodsComponent implements OnInit {
	public podsDataSource: PodsDataSource;

	constructor(
		private podService: PodService,
		private router: Router,
		private route: ActivatedRoute) { }

	ngOnInit() {
		this.podsDataSource = new PodsDataSource(this.podService);
	}

	selectPod(pod: PodItem) {
		this.router.navigate([pod.name], {relativeTo: this.route});
	}
}

/**
 * A table data source to display the Pods from the selected Realm.
 */
export class PodsDataSource extends DataSource<PodItem> {

	// Columns to display
	columns = ['name', 'type', 'hostname', 'public_key'];

	constructor(private podService: PodService) {
		super();
	}

	connect(collectionViewer: CollectionViewer)
			: Observable<Array<PodItem>> {
		try {
			return this.podService.pods();
		} catch (e) {
			return empty();
		}
	}

	disconnect(collectionViewer: CollectionViewer) {}
}
