import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from './signup.validators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {

	public serverErrorMessage: string;

	public signupForm = this.formBuilder.group({
		firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
		lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
		email: new FormControl('', [Validators.required, Validators.email]),
		lang: new FormControl('', [Validators.required]),
		company: new FormControl(),
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
		confirmPassword: new FormControl('', []),
	}, {
		// check whether our password and confirm password match
		validator: MustMatch('password', 'confirmPassword')
	});

	// Whether the form is being submitted
	readonly isSubmitting$ = new BehaviorSubject<boolean>(false);
	readonly unSubscriber = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		public authService: AuthService) { }

	// // convenience getters for easy access to form fields
	get firstName() { return this.signupForm.get('firstName'); }
	get lastName() { return this.signupForm.get('lastName'); }
	get lang() { return this.signupForm.get('lang'); }
	get email() { return this.signupForm.get('email'); }
	get company() { return this.signupForm.get('company'); }
	get password() { return this.signupForm.get('password'); }
	get confirmPassword() { return this.signupForm.get('confirm-password'); }

	ngOnInit() {
		this.route.queryParamMap.pipe(
			tap(param => {
				const langParam = param.get('lang');
				if (langParam) {
					this.lang.setValue(langParam);
				}
			}),
			takeUntil(this.unSubscriber),
		).subscribe();
	}

	ngOnDestroy() {
		this.unSubscriber.next();
		this.unSubscriber.complete();
	}

	async onSubmit() {

		if (!this.signupForm.valid) {
			console.error('Invalid signup form');
			return;
		}

		try {
			this.isSubmitting$.next(true);

			await this.authService.createAccount(
				this.firstName.value,
				this.lastName.value,
				this.lang.value,
				this.email.value,
				this.password.value,
				this.company.value,
			);

			await this.authService.login(
				this.email.value,
				this.password.value
			);

			this.router.navigate(['/'], {queryParams: this.route.snapshot.queryParams});
		} catch (e) {
			this.isSubmitting$.next(false);

			if (e.status === 409) {
				this.email.setErrors({ taken: true });
			} else {
				if (e.status === 504) {
					this.serverErrorMessage = 'Unable to connect to storro.com';
				} else {
					this.serverErrorMessage = 'Something went wrong. Contact us if this happens again.';
				}
			}
		}
	}

	routeToLogin() {
		this.router.navigate(['/login'], {queryParams: this.route.snapshot.queryParams});
	}
}
