import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventLogFrame } from '../../../realm.service';
import { UserEventLogsComponent } from '../event_logs.component';

// A list of all known peers from a pod. Clicking a list item
// shows details about the peer.

@Component({
	selector: 'app-event-frames',
	templateUrl: './event_frames.component.html',
	styleUrls: ['./event_frames.component.scss']
})
export class UserEventFramesComponent implements OnInit {

	public columns = ['description', 'location', 'parameters'];
	public frames: Array<EventLogFrame>;

	constructor(public dialogRef: MatDialogRef<UserEventLogsComponent>, @Inject(MAT_DIALOG_DATA) private data: any)
	{
		this.frames = data.frames;
	}

	ngOnInit() {
	}

	toString(obj: Object) {
		return JSON.stringify(obj);
	}
}
