import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealmService } from '../../realm.service';

// Dialog for renaming the realm.

@Component({
	selector: 'app-rename-realm',
	templateUrl: './rename-realm.component.html',
	styleUrls: ['./rename-realm.component.scss']
})
export class RenameRealmComponent implements OnInit {

	public errorMessage: string;
	public isBusy = false;

	public renameRealmForm = new FormGroup({
		realmNameField: new FormControl('', [Validators.required, Validators.minLength(2)])
	});

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<RenameRealmComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any) {
			this.renameRealmForm.get('realmNameField').setValue(data.currentName);
		}

	ngOnInit(): void {
	}

	async submit() {
		try {
			this.isBusy = true;
			await this.realmService.renameRealm(this.renameRealmForm.get('realmNameField').value);
			this.isBusy = false;
			this.dialogRef.close({changed: true});
		} catch (e) {
			this.isBusy = false;
			this.errorMessage = e.error;
			console.error(e);
		}
	}

	close() {
		this.dialogRef.close({changed: false});
	}

	get realmNameField() { return this.renameRealmForm.get('realmNameField'); }
}
