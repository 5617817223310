import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RealmService, EventLogs, EventLogFrame } from '../../realm/realm.service';
import { EventFramesComponent } from './event_frames/event_frames.component';


// A list of all known peers from a pod. Clicking a list item
// shows details about the peer.

@Component({
	selector: 'app-event-logs',
	templateUrl: './event_logs.component.html',
	styleUrls: ['./event_logs.component.scss']
})
export class EventLogsComponent implements OnInit {

	public eventLogs: EventLogs;
	public columns = ['timestamp', 'category', 'severity', 'frames'];

	constructor(
		private realmService: RealmService,
		private route: ActivatedRoute,
		private dialog: MatDialog
	) { }

	ngOnInit() {
		if (this.realmService.realmIdFromPath$.value) {
			this.realmService.eventLogs().subscribe(response => this.eventLogs = response);
		}
	}

	localize(date: string) {
		const d = new Date(date);
		return d.toLocaleString('en-GB');
	}

	openFrames(frames: Array<EventLogFrame>) {
		const dialogRef = this.dialog.open(EventFramesComponent, {
			data: {
				frames: frames
			},
			autoFocus: false
		});
	}
}
