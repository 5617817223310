import { Component, OnInit } from '@angular/core';
import { PodService, PodDetails, PodAbout, PodLicenseInfo } from '../pod.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-pod',
	templateUrl: './pod.component.html',
	styleUrls: ['./pod.component.scss']
})
export class PodComponent implements OnInit {
	public pod$: Observable<PodDetails|undefined>;
	public about$: Observable<PodAbout>;
	public licenseInfo$: Observable<PodLicenseInfo>;

	constructor(private podService: PodService) {
	}

	ngOnInit() {
		if (this.podService.podIdFromPath$.value) {
			this.pod$ = this.podService.podFromPath$;
			this.about$ = this.podService.about();
			this.licenseInfo$ = this.podService.licenseInfo();
		}
	}

	public async restartPod() {
		await this.podService.restartPod();
	}

	public print(licenseInfo: Array<PodLicenseInfo>)
	{
		console.log(JSON.stringify(licenseInfo));
	}

}
