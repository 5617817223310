import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PaymentService, StorageProduct } from '../../purchase/payment.service';
import { RealmService } from '../realm.service';
import { environment } from '../../../environments/environment';
import { finalize, tap } from 'rxjs/operators';

declare const Chargebee;

const FREE_SPACE_AMOUNT = 500;
const ADDITIONAL_SPACE_AMOUNT = 1000;

@Component({
	selector: 'app-billing',
	templateUrl: './billing.component.html',
	styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
	serverErrorMessage: string;
	userLicenseCount = 0;
	additionalStorageCount = 0;
	addUserLicenseCount = 0; // holds the qty of new users to be added
	addAdditionalStorageCount = 0; // holds the qty of storage to be added
	isTrial = false;
	trialDaysLeft = 0;
	availableStorageProduct: StorageProduct; // hold the storage product that is attached to the period of the subscription

	private portalSession: any; // fetched portal session from the API
	private customerSubscription: any; // fetched customer subscription from the API

	readonly isLoading$ = new BehaviorSubject<boolean>(false);
	readonly isLoaded$ = new BehaviorSubject<boolean>(false);
	readonly isSubmitting$ = new BehaviorSubject<boolean>(false);

	get currentTotalFreeSpace(): number {
		return FREE_SPACE_AMOUNT * this.userLicenseCount;
	}

	get currentTotalAdditionalSpace(): number {
		return ADDITIONAL_SPACE_AMOUNT * this.additionalStorageCount;
	}

	get addTotalAdditionalSpace(): number {
		return this.addAdditionalStorageCount * ADDITIONAL_SPACE_AMOUNT;
	}

	constructor(
		private paymentService: PaymentService,
		private realmService: RealmService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
		Chargebee.init({
			site: environment.chargeBeeSiteName,
			enableGTMTracking: true,
		});

		this.load();
	}

	/**
	 * fetch the billing details for the current user subscriptions
	 */
	private load(): void {
		this.isLoading$.next(true);

		this.paymentService.getBillingDetails().pipe(
			tap(({storage_product, portal_session, customer_subscription}) => {
				this.isLoading$.next(false);
				this.isLoaded$.next(true);

				this.portalSession = portal_session;
				this.customerSubscription = customer_subscription;

				this.userLicenseCount = customer_subscription.licenseQty;
				this.additionalStorageCount = customer_subscription.storageQty ?? 0;
				this.isTrial = customer_subscription.isTrial;
				this.trialDaysLeft = customer_subscription.trialDaysLeft;
				this.availableStorageProduct = storage_product;
			}),
			finalize(() => this.isLoading$.next(false))
		).subscribe({
			error: () => this.serverErrorMessage = 'We cannot process your request at this time. Please try again later or contact our support.'
		});
	}

	/**
	 * Open a chargeBee dialog for viewing the subscription details
	 */
	openDialog(): void {
		const chargebeeInstance = Chargebee.getInstance();
		chargebeeInstance.setPortalSession(() => {
			return Promise.resolve(this.portalSession);
		});

		const cbPortal = chargebeeInstance.createChargebeePortal();
		cbPortal.open();
	}

	/**
	 * Open a ChargeBee dialog to update either the license or storage qty
	 */
	upgrade(): void {
		this.isSubmitting$.next(true);
		this.serverErrorMessage = '';

		const licenseQty = this.userLicenseCount + this.addUserLicenseCount;
		const storageQty = this.additionalStorageCount + this.addAdditionalStorageCount;

		this.paymentService.checkout(
			this.customerSubscription.licenseId,
			licenseQty,
			this.customerSubscription.storageId ?? this.availableStorageProduct.id,
			storageQty,
			this.customerSubscription.subscriptionId,
		).pipe(
			tap(hostedPage => {
				const chargebeeInstance = Chargebee.getInstance();
				chargebeeInstance.openCheckout({
					hostedPage: () => {
						return Promise.resolve(hostedPage);
					},
					success: () => {
						this.isSubmitting$.next(false);
						this.reset();
						this.load();
					},
					close: () => {
						this.isSubmitting$.next(false);
						this.changeDetectorRef.detectChanges();
					},
				});
			}),
		).subscribe({
			error: () => {
				this.serverErrorMessage = 'We cannot process your request at this time. Please try again later or contact our support.';
				this.isSubmitting$.next(false);
				this.reset();
			}
		});
	}

	increaseAdditionalUsers(): void {
		this.addUserLicenseCount++;
	}

	decreaseAdditionalUsers(): void {
		if (this.addUserLicenseCount > 0) {
			this.addUserLicenseCount--;
		}
	}

	increaseAdditionalStorage(): void {
		this.addAdditionalStorageCount++;
	}

	decreaseAdditionalStorage(): void {
		if (this.addAdditionalStorageCount > 0) {
			this.addAdditionalStorageCount--;
		}
	}

	private reset(): void {
		this.addAdditionalStorageCount = 0;
		this.addUserLicenseCount = 0
	}
}
