import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RealmService } from '../realm/realm.service';

/**
 * tier price makes it possible to have a dynamic set pricing per product
 */
interface PriceTier {
	startUnit: number;
	endUnit?: number;
	price: number;
}

type Period = 'year' | 'month';

/**
 * the basic interface for the product
 */
export interface ProductBase {
	id: string;
	name: string;
	periodUnit: Period;
}

/**
 * the license product has been built from different prices as it comes with tier pricing.
 * qty 1-9 has a different price then a qty 10 or higher
 */
export interface LicenseProduct extends ProductBase {
	prices: PriceTier[];
}

/**
 * the storage product use a simple price property
 */
export interface StorageProduct extends ProductBase {
	price: number;
}

/**
 * the response from the server with both the license and storage product
 */
interface ProductResponse {
	license_products: LicenseProduct[];
	storage_products: StorageProduct[];
}

/**
 * the checkout response from the server with an hosted_page object
 */
interface CheckoutResponse {
	hosted_page: any;
}

/**
 * the customer response from the server with the customer ID
 */
interface CustomerIdResponse {
	customer_id: string;
}

/**
 * the subscription for a customer/real that holds both the license and/or the storage product
 */
export interface CustomerSubscription {
	subscriptionId: string;
	customerId: string;
	trialDaysLeft: number;
	isTrial: boolean;
	licenseId: string;
	licenseQty: number;
	storageId?: string;
	storageQty?: number;
}

/**
 * the Portal session response from the server with both portal_session, customer_subscription and storage_product
 */
interface PortalSessionResponse {
	portal_session: any;
	customer_subscription: CustomerSubscription;
	storage_product: StorageProduct;
}

@Injectable({
	providedIn: 'root'
})
/**
 * This is the PaymentService that create/fetch data from the API.storro
 *
 * As PaymentProvider we are using Chargebee that comes with Plan and Addon as types.
 *
 * In Storro we are using license(s) and Storage. So the mapping should be:
 * plan -> license
 * addon -> storage
 *
 * To integrate with Chargebee we are using the Checkout and Self-Service portal via API
 * See https://www.chargebee.com/checkout-portal-docs/api-checkout.html
 * See https://www.chargebee.com/checkout-portal-docs/api-portal.html
 */
export class PaymentService {
	constructor(
		private http: HttpClient,
		private realmService: RealmService,
	) {
	}

	/**
	 * Get the products, both license and Storage
	 */
	getProducts(): Observable<ProductResponse> {
		return this.http.get<ProductResponse>('/api/payment/products');
	}

	/**
	 * Create hostedPage object that is need to show the dialog for the checkout
	 */
	checkout(
		licenseProductID: string,
		licenseProductQty: number,
		storageProductID: string,
		storageProductQty: number,
		subscriptionId?: string,
	): Observable<any> {
		return this.http.post<CheckoutResponse>('/api/payment/checkout', {
			license_product_id: licenseProductID,
			license_product_qty: licenseProductQty,
			storage_product_id: storageProductID,
			storage_product_qty: storageProductQty,
			subscription_id: subscriptionId,
		}).pipe(
			map(({hosted_page}) => hosted_page)
		);
	}

	/**
	 * Get the created customer by the given hostedPageId
	 */
	getCustomerIdByHostedPageId(hostedPageId: string,): Observable<string> {
		return this.http.get<CustomerIdResponse>(`/api/payment/customer/${hostedPageId}`).pipe(
			map(({customer_id}) => customer_id)
		);
	}

	/**
	 * Get the billing details for a realm
	 */
	getBillingDetails(): Observable<PortalSessionResponse> {
		try {
			const realmID = this.realmService.realmFromPathId();
			return this.http.get<PortalSessionResponse>(`/api/payment/realm/${realmID}`);
		} catch (e) {
			return throwError(e);
		}
	}
}
