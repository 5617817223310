import { Component, OnInit } from '@angular/core';
import { PodService, PeerKeyList, PeerConnectionState, ConnectionState, PeerAddress } from '../pod.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RealmService } from '../../realm/realm.service';

// A list of all known peers from a pod. Clicking a list item
// shows details about the peer.

@Component({
	selector: 'app-network',
	templateUrl: './network.component.html',
	styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

	private podId: string;
	public selectedPeerKey: string;
	peers$: Observable<PeerKeyList>;
	connectedPeers$: Observable<PeerKeyList>;
	peerNetworkState$: Observable<PeerConnectionState>;
	podPeerNetworkAddress$: Observable<PeerAddress>;

	constructor(
		private podService: PodService,
		private realmService: RealmService,
		private route: ActivatedRoute,
	) {
	}

	ngOnInit() {
		if (this.realmService.realmIdFromPath$.value) {
			this.podId = this.podService.podNameFromRoute(this.route.snapshot);
			this.peers$ = this.podService.peers();
			this.connectedPeers$ = this.podService.peers(
				ConnectionState.Connected);
		}
	}

	public selectPeerKey(peerKey: string) {
		this.selectedPeerKey = peerKey;
		this.peerNetworkState$ =
			this.podService.peerNetworkState(peerKey);
		this.podPeerNetworkAddress$ =
			this.podService.peerNetworkAddress(peerKey);
	}
}
