import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

// The page that is shown when the admin invitation
// button is clicked in an email.

@Component({
	selector: 'app-join',
	templateUrl: './join.component.html',
	styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

	// The name of the realm
	public realmName: string;

	// The token that is part of the email url.
	private token: string;

	// An error message that can be set on
	// init or when the button is clicked.
	public errorMsg: string;

	// To show the throbber.
	public isLoading: boolean;

	constructor(
			private authService: AuthService,
			private route: ActivatedRoute,
			private router: Router) {
		this.isLoading = true;
		this.token = this.route.snapshot.queryParamMap.get('token');
	}

	ngOnInit() {
		this.authService.adminInvitationDetails(this.token).subscribe(result => {
			this.realmName = result.realm;
			this.isLoading = false;
		}, err => {
			this.setError(err.status, err.error);
			this.isLoading = false;
		});
	}

	setError(errorCode: number, errorString: string) {
		if (errorCode === 409) { // Conflict
			this.errorMsg = errorString;
		} else if (errorCode === 410) { // Gone
			this.errorMsg = errorString;
		} else if (errorCode === 400) { // Bad request
			this.errorMsg = errorString;
		} else if (errorCode === 500) { // Internal error
			this.errorMsg = 'Internal server error';
		} else if (errorCode === 504) { // Gateway error
			this.errorMsg = 'Failed to connect to the server';
		}
	}

	// The join button is clicked.
	async onJoin() {
		try {
			this.isLoading = true;
			await this.authService.acceptAdminInvitation(this.token);
			this.router.navigate(['/']);
		} catch (e) {
			this.setError(e.status, e.error);
			this.isLoading = false;
		}
	}
}
