import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-login-totp',
	templateUrl: './login-totp.component.html',
	styleUrls: ['./login-totp.component.scss']
})
export class LoginTotpComponent implements OnInit {

	private token = '';
	public totpError: string|undefined = undefined;

	constructor(
		public dialogRef: MatDialogRef<LoginTotpComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		this.totpError = data.totpError;
	}

	ngOnInit(): void {
	}

	onTokenChange(text: string) {
		this.token = text;
	}

	onSubmit() {
		this.dialogRef.close({token: this.token, submitted: true});
	}
}
