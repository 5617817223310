import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { hash } from 'bcryptjs';
import { SinglePageWrapperService } from '../single-page-wrapper/single-page-wrapper.service';

// Data format for the returned JSON from /api/admins/self.
export interface AdminDetails {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	lang: string;
	company: string;
	isSuperAdmin: boolean;
	emailVerified: boolean;
	totpEnabled: boolean;
}

export interface AdminRealms {
	count: number; // The amount of realms that I'm admin of.
	default: string|undefined; // The default realm identifier.
}

export interface LoggedInAdmin {
	profile: AdminDetails;
	realms: AdminRealms;
}

// Data format for the returned JSON from /api/realm/invitation.
export interface InvitationDetails {
	realm: string;
}

export interface PasswordResetRequest {
	firstName: string;
	lastName: string;
}

export interface TotpSharedSecret {
	shared_secret: string;
	shared_secret_url: string;
}

/** A class that handles authentication to the api */
@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private saltRounds = 10;

	loggedInUser$: BehaviorSubject<LoggedInAdmin|undefined> =
		new BehaviorSubject<LoggedInAdmin|undefined>(undefined);

	public account = this.http.get<LoggedInAdmin>('/api/admins/self');

	constructor(
		private http: HttpClient,
		private singlePageWrapperService: SinglePageWrapperService,
		) { }

	public login(email: string, pass: string, totp?: string): Promise<boolean> {

		this.logout();

		return new Promise(async (resolve, reject) => {
			const httpOptions = {
				headers: new HttpHeaders({
					Authorization: 'Basic ' + btoa(`${email}:${pass}`)
				})
			};

			// Data format for the returned JSON.
			interface TokenData {
				token: string;
			}

			try {
				let url = '/api/admins/self/token';
				if (totp) {
					url += '?totp=' + totp;
				}
				const tokenData = await this.http.get<TokenData>(
					url,
					httpOptions).toPromise();
				localStorage.setItem('token', tokenData.token);
				resolve(true);
			} catch (e) {
				if (e.status === 401) {
					resolve(false);
				} else {
					reject(e);
				}
			}
		});
	}

	public logout(): void {
		this.singlePageWrapperService.setBackRoute('');
		localStorage.removeItem('token');
	}

	public isAuthenticated(): boolean {
		return this.bearerToken() !== undefined;
	}

	public bearerToken(): string | undefined {
		const token = localStorage.getItem('token');
		return token ? token : undefined;
	}

	/**
	 * Create a new admin account.
	 */
	public async createAccount(
			firstName: string,
			lastName: string,
			lang: string,
			emailAddress: string,
			pwd: string,
			companyName?: string,
		) {
		this.logout();
		await this.http.put('/api/admins/self', {
			firstName,
			lastName,
			lang,
			email: emailAddress,
			password: await hash(pwd, this.saltRounds),
			company: companyName,
			is_hashed: true
		}).toPromise();
	}

	/**
	 * Accept an admin invitation based on the secret you received by email
	 * @param emailSecret The secret received by email
	 */
	public async acceptAdminInvitation(emailSecret: string) {
		await this.http.put(`/api/realms/invitation`,
			{ secret: emailSecret }).toPromise();
	}

	/**
	 * Returns some details about the invitation based on the secret.
	 * @param emailSecret The secret received by email
	 */
	public adminInvitationDetails(emailSecret: string): Observable<InvitationDetails> {
		return this.http.get<InvitationDetails>(
			`/api/realms/invitation?token=${emailSecret}`);
	}

	/**
	 * Fetch the password reset request.
	 * currently only used to check if the request is still
	 * valid and to fetch the requester's name.
	 *
	 * @param secret the secret associated with the reset Request.
	 */
	public passwordResetDetails(secret: string): Observable<PasswordResetRequest> {
		return this.http.get<PasswordResetRequest>(
			`/api/admins/reset_password?secret=${secret}`);
	}

	/**
	 * Reset the admin's password with the new password provided.
	 *
	 * @param password the new password.
	 * @param secret the secret associated with the reset request.
	 * @param secondFactor the optional second factor auth code, if required.
	 */
	public async resetAdminPassword(password: string, secret: string, secondFactor?: string) {
		await this.http.put('/api/admins/reset_password', {
			password: await hash(password, this.saltRounds),
			secret: secret,
			is_hashed: true
		}).toPromise();
	}

	/**
	 * Request for a reset url to be sent to the provided email,
	 *
	 * @param email the email address to send the reset url to.
	 * @param secondFactor optional second factor auth code.
	 */
	public async forgotAdminPassword(email: string, secondFactor?: string) {
		await this.http.put('/api/admins/forgot_password', {
				email: email,
				second_factor: secondFactor
			}).toPromise();
	}

	/**
	 * Update your admin account.
	 */
	public async updateAccount(
			firstName: string,
			lastName: string,
			lang: string,
			emailAddress: string,
			companyName?: string) {
		await this.http.patch('/api/admins/self', {
			firstName,
			lastName,
			lang,
			email: emailAddress,
			company: companyName,
		}).toPromise();
		this.loggedInUser$.next(await this.account.toPromise());
	}

	/**
	 * Update your admin password
	 */
	public updateAccountPassword(
			currentPassword: string,
			newPassword: string,
			repeatNewPassword: string) {
		if (newPassword !== repeatNewPassword) {
			throw Error('Passwords don\'t match');
		}
		return this.http.put('/api/admins/change_password', {
			password: currentPassword,
			new_password: newPassword
		}).toPromise();
	}

	/**
	 * Verify your email address
	 * @param token The token received by email
	 */
	public async verifyEmail(token: string) {
		await this.http.put('/api/admins/verify_email', {
			verificationToken: token,
		}).toPromise();
	}

	/**
	 * Get the "Time-based One-time Password" shared secret and QRCode url for
	 * saving the shared secret in your authenticator app (like Google
	 * Authenticator).
	 */
	public getTotpSharedSecret(): Observable<TotpSharedSecret> {
		return this.http.get<TotpSharedSecret>('/api/admins/self/totp');
	}

	/**
	 * Enable "Time-based One-time Password" for Two Factor Authenticator.
	 * @param timeBasedOneTimePassword A generated token from your authenticator app
	 */
	public async enable2fa(timeBasedOneTimePassword: string) {
		await this.http.patch<TotpSharedSecret>('/api/admins/self/totp', {
			enable: true,
			token: timeBasedOneTimePassword
		}).toPromise();
		this.loggedInUser$.next(await this.account.toPromise());
	}

	/**
	 * Disable "Time-based One-time Password" for Two Factor Authenticator.
	 */
	public async disable2fa() {
		await this.http.patch<TotpSharedSecret>('/api/admins/self/totp', {
			enable: false
		}).toPromise();
		this.loggedInUser$.next(await this.account.toPromise());
	}

	/**
	 * Delete an admin
	 */
	public removeAdmin(userId: number): Observable<void> {
		return this.http.delete<void>(`/api/admins/${userId}`);
	}
}
