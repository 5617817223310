import { Component, OnInit } from '@angular/core';
import { PodService, ProjectList, ProjectDetails } from '../pod.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

	projectId: string;
	projects$: Observable<ProjectList>;
	projectDetails$: Observable<ProjectDetails>;

	constructor(
		private podService: PodService
	) {
	}

	ngOnInit() {
		if (this.podService.podIdFromPath$.value) {
			this.projects$ = this.podService.projects();
		}
	}

	selectProject(projectId) {
		this.projectId = projectId;
		this.projectDetails$ = this.podService.projectDetails(projectId);
	}
}
