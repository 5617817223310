import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	OnInit,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultInfoBlockComponent } from './default-info-block/default-info-block.component';
import { SinglePageWrapperService } from './single-page-wrapper.service';

/**
 * A Single page gui component used for the routes
 */
@Component({
	selector: 'app-single-page-wrapper',
	templateUrl: './single-page-wrapper.component.html',
	styleUrls: ['./single-page-wrapper.component.scss']
})
export class SinglePageWrapperComponent implements OnInit, AfterViewInit {
	@ViewChild('sidebarPlaceholder', {read: ViewContainerRef})
	private viewRef: ViewContainerRef;

	private sidebarComponent: any; // TODO add typechecking
	readonly showBackButton$ = this.singlePageWrapperService.backRoute$.pipe(
		map(url => url !== ''),
	);

	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map(result => result.matches)
		);

	constructor(
		private breakpointObserver: BreakpointObserver,
		private componentFactoryResolver: ComponentFactoryResolver,
		private activatedRoute: ActivatedRoute,
		private changeDetectionRef : ChangeDetectorRef,
		private singlePageWrapperService : SinglePageWrapperService,
		private router: Router) {
	}

	ngOnInit() {
		this.activatedRoute.data.subscribe(data => {
			this.sidebarComponent = data.component ? data.component : DefaultInfoBlockComponent
		});
	}

	ngAfterViewInit() {
		if(this.sidebarComponent) {
			const resolver = this.componentFactoryResolver.resolveComponentFactory(this.sidebarComponent);
			const componentFactory =  this.viewRef.createComponent(resolver);

			// Mark the view for changes as we inject a new component after the view got rendered
			// otherwise we fall into the ExpressionChangedAfterItHasBeenCheckedError error
			this.changeDetectionRef.detectChanges();
		}
	}

	backButtonClick() {
		this.router.navigate([this.singlePageWrapperService.backRoute$.getValue()]);
	}
}
