import { Component, Inject } from '@angular/core';
import { RealmService, UserDetails } from '../../realm.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-invite',
	templateUrl: './remove.component.html',
	styleUrls: ['./remove.component.scss']
})
export class RemoveUserComponent {
	public errorMessage: string;
	public isBusy = false;
	public user: UserDetails;

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<RemoveUserComponent>, @Inject(MAT_DIALOG_DATA) private data: any)
	{
		this.user = data.user;
	}

	async submit() {
		if (this.errorMessage) { // error condition so just close.
			this.dialogRef.close({removed: false});
		}

		try {
			this.isBusy = true;
			await this.realmService.removeUser(this.user.id);
			this.isBusy = false;
			this.dialogRef.close({removed: true});
		} catch (e) {
			this.isBusy = false;
			this.errorMessage = e.error;
		}
	}

	close() {
		this.dialogRef.close({removed: false});
	}
}
