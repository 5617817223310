import { PodType, PodService } from '../../pods/pod.service';

export enum DeployStage {
	Pending = 'Pending',
	Running = 'Creating',
	Failed = 'Failed, please contact us.',
	Success = 'Successfully deployed.'
}

/**
 * A class to deploy a Realm Pod and Storage Pod sequentially.
 */
export class Deploy {
	private realmStage: DeployStage = DeployStage.Pending;
	private storageStage: DeployStage = DeployStage.Pending;
	private realmText: string;
	private storageText: string;
	private deployTextIndex = 0;
	private readonly deployTexts = Array<string>();
	private success = false;

	private maxRetry = 10;
	private retryCountStoragePod = 0;
	private retryCountRealmPod = 0;
	private retryInterval = 5000;

	constructor(
		realmIsCreated: boolean,
		storageIsCreated: boolean,
		private podService: PodService,
		private callbackFn: () => void,
	) {
		this.realmStage = realmIsCreated
			? DeployStage.Success : DeployStage.Pending;
		this.realmText = this.realmStage.toString();
		this.storageStage = storageIsCreated
			? DeployStage.Success : DeployStage.Pending;
		this.storageText = this.storageStage.toString();

		this.deployTexts.push('Pulling distribution...');
		this.deployTexts.push('Installing distribution...');
		this.deployTexts.push('Setting up environment...');
		this.deployTexts.push('Registering environment...');
		this.deployTexts.push('Finalizing environment...');
	}

	public get realmDeployStage(): DeployStage {
		return this.realmStage;
	}
	public get storageDeployStage(): DeployStage {
		return this.storageStage;
	}
	public get realmDeployStatusText(): string {
		return this.realmText;
	}
	public get storageDeployStatusText(): string {
		return this.storageText;
	}
	public get finishedSuccessfully(): boolean {
		return this.success;
	}

	public start() {
		if (this.realmStage === DeployStage.Pending) {
			this.createRealmPod();
		} else if (this.storageStage === DeployStage.Pending) {
			this.createStoragePod();
		} else {
			this.success = true;
		}
	}

	private createRealmPod() {
		this.realmStage = DeployStage.Running;
		this.realmText = this.realmStage.toString();

		this.deployTextIndex = 0;
		this.realmText = this.deployTexts[this.deployTextIndex];
		const intervalObj = setInterval(() => {
			if (this.deployTextIndex < this.deployTexts.length) {
				this.realmText = this.deployTexts[this.deployTextIndex++];
			}
		}, 2000);

		this.podService.createPod(PodType.Realm).subscribe(
			res => {
				this.realmStage = DeployStage.Success;
				this.realmText = this.realmStage.toString();
				if (this.storageStage === DeployStage.Pending) {
					this.createStoragePod();
				} else {
					this.success = true;
				}
				clearInterval(intervalObj);
			},
			() => {
				clearInterval(intervalObj);

				// due some race condition we should have the POD creating retry when failed
				if(this.retryCountRealmPod < this.maxRetry) {
					this.retryCountRealmPod++;
					setTimeout(() => this.createRealmPod(), this.retryInterval);
				} else {
					this.realmStage = DeployStage.Failed;
					this.realmText = this.realmStage.toString();
				}
			}
		);
	}

	private createStoragePod() {
		this.storageStage = DeployStage.Running;
		this.storageText = this.storageStage.toString();

		this.deployTextIndex = 0;
		this.storageText = this.deployTexts[this.deployTextIndex];
		const intervalObj = setInterval(() => {
			if (this.deployTextIndex < this.deployTexts.length) {
				this.storageText = this.deployTexts[this.deployTextIndex++];
			}
		}, 2000);

		this.podService.createPod(PodType.Storage).subscribe(
			res => {
				this.storageStage = DeployStage.Success;
				this.storageText = this.storageStage.toString();
				this.success = true;
				clearInterval(intervalObj);
				this.callbackFn();
			},
			() => {
				clearInterval(intervalObj);

				// due some race condition we should have the POD creating retry when failed
				if(this.retryCountStoragePod < this.maxRetry) {
					this.retryCountStoragePod++;
					setTimeout(() => this.createStoragePod(), this.retryInterval);
				} else {
					this.storageStage = DeployStage.Failed;
					this.storageText = this.storageStage.toString();
				}
			}
		);
	}
}
