import { Component } from '@angular/core';

@Component({
	selector: 'app-default-info-block',
	templateUrl: './default-info-block.component.html',
	styleUrls: ['./default-info-block.component.scss']
})
export class DefaultInfoBlockComponent {

	constructor() {
	}

}
