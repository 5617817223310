import { Component, OnInit } from '@angular/core';
import { RealmService } from '../realm.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/services/notification.service';
import { MessageBoxComponent } from '../../message_box/message_box.component';
import { AuthService } from '../../auth/auth.service';
import { RenameRealmComponent } from './rename-realm/rename-realm.component';

@Component({
	selector: 'app-config',
	templateUrl: './config.component.html',
	styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

	public config$: Observable<any>;
	public versions$: Observable<Array<string>>;

	public selectedVersion: string;

	public admin$ = this.authService.loggedInUser$;

	constructor(
		private realmService: RealmService,
		private authService: AuthService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
		try {
			this.config$ = this.realmService.config();
			this.config$.subscribe((config) => this.selectedVersion = config.current_version);
			this.versions$ = this.realmService.versions();
		} catch (err) {
		}
	}

	async updateCurrentVersion(selectedVersion: string) {
		try {
			await this.realmService.updateCurrentVersion(selectedVersion);
			this.ngOnInit();
			this.notificationService.open(`updated current version to ${selectedVersion}`);
		} catch (e) {
			const dialogRef = this.dialog.open(MessageBoxComponent, {
				data: {
					title: "Error updating version",
					message: e,
					isError: true
				}
			});
			dialogRef.afterClosed().subscribe(result => this.ngOnInit());
		}
	}

	async renameRealm(currentRealmName: string) {
		const dialogRef = this.dialog.open(RenameRealmComponent, {
			data: {
				currentName: currentRealmName
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result.changed) {
				this.config$ = this.realmService.config();
			}
		});
	}

	compareVersions(first: string, second: string)
	{
		return first === second;
	}
}
