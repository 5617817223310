import { Pipe, PipeTransform } from '@angular/core';
import filesize from 'filesize';

/**
 * Can be used in templates to convert number to human readable filesize.
 */
@Pipe({name: 'filesize'})
export class FileSizePipe implements PipeTransform {
	transform(value: number, base: number = 10): string {
        return filesize(value, {base: base});
	}
}
