import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RealmService } from '../realm/realm.service';
import { AuthService, LoggedInAdmin } from '../auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccountComponent } from '../auth/account/account.component';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
	.pipe(
		map(result => result.matches)
	);

	admin$ = this.authService.loggedInUser$;
	realmFromPath$ = this.realmService.realmFromPath$;

	constructor(
		private breakpointObserver: BreakpointObserver,
		private authService: AuthService,
		private router: Router,
		private realmService: RealmService,
		private dialog: MatDialog) {
	}

	public logout() {
		this.authService.logout();
		this.router.navigate(['/login']);
	}

	// Show the account dialog.
	openDialogAccount() {
		this.dialog.open(AccountComponent);
	}
}
