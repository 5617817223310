import { Component, Inject } from '@angular/core';
import { RealmService, UserDetails, Device } from '../../realm.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-remove-device',
	templateUrl: './remove_device.component.html',
	styleUrls: ['./remove_device.component.scss']
})
export class RemoveDeviceComponent {
	public errorMessage: string;
	public isBusy = false;
	public user: UserDetails;
	public device: Device;

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<RemoveDeviceComponent>, @Inject(MAT_DIALOG_DATA) private data: any)
	{
		this.user = data.user;
		this.device = data.device;
	}

	async submit() {
		if (this.errorMessage) // error condition so just close.
			this.dialogRef.close();
		
		try {
			this.isBusy = true;
			await this.realmService.removeDevice(this.user.id, this.device.peer_key);
			this.isBusy = false;
			this.dialogRef.close();
		} catch (e) {
			this.isBusy = false;
			this.errorMessage = e.error;
		}
	}

	close() {
		this.dialogRef.close();
	}
}
