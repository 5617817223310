import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export type LoadingButtonType = 'button' | 'submit';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
	@Input() isDisabled = false;
	@Input() isLoading = false;
	@Input() color: ThemePalette = 'primary';
	@Input() buttonType: LoadingButtonType = 'button';
	@Output() readonly buttonClick = new EventEmitter();

	loadingSize = 20;

	public get disabled(): boolean {
		return this.isLoading ? true : this.isDisabled;
	}
}
