import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

/**
 * An interceptor for doing http requests to api.storro.com
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

	// Used by the handle401 method.
	private isIn401 = false;

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private router: Router,
		) { }

	// Select a response type based on the HTTP METHOD. This is
	// required because PUT will return text and not JSON. This
	// causes parsing errors in the http client.
	private responseType(method: string): 'json' | 'text' {
		switch (method) {
			case 'GET': case 'POST':
				return 'json';
			case 'PUT': case 'DELETE': default:
				return 'text';
		}
	}

	/**
	 * When a 401 Unauthorized is received.
	 * Check if we can fetch api/admin. Log the admin out when that
	 * also returns a 401. This means the admin token is not valid
	 * anymore.
	 */
	private async handle401(err: HttpErrorResponse) {
		if (this.isIn401) {
			// We are already handling a 401. Exit the method because
			// the result from the http GET below could also result
			// in a 401 which brings us in an infinite loop.
			return;
		}
		this.isIn401 = true;
		try {
			await this.http.get('/api/admins/self').toPromise();
		} catch (e) {
			this.authService.logout();
			this.router.navigate(['/login']);
		}
		this.isIn401 = false;
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// It's a request to the Storro api backend.
		// The configuration is in proxy.conf.json.
		if (request.url.startsWith('/api/')) {

			// Add authorization header if available
			const token = this.authService.bearerToken();
			if (token) {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}` },
					responseType: this.responseType(request.method)
				});
			} else {
				request = request.clone({
					responseType: this.responseType(request.method)
				});
			}
		}

		// Tap the result to check for a 401.
		return next.handle(request).pipe(tap(
			response => { }, // We're not interested in the response.
			(err: HttpErrorResponse) => {
				if (err.status === 401) {
					this.handle401(err);
				}
			}));
	}
}
