import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
	public httpCode: string;
	public message: string;
	constructor(private route: ActivatedRoute) {
		this.httpCode = route.snapshot.data.code;
		this.message = route.snapshot.data.message;
	}
}
