import { Component } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService, TotpSharedSecret } from '../auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import * as QRCode from 'qrcode';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent {

	public serverErrorMessage = new Array<string>();

	totpSharedSecret$: Observable<TotpSharedSecret>;
	public qrCodeUrl: string;
	public sharedSecret: string;

	public totpEnabled = false;

	public updateAccountForm = this.formBuilder.group({
		firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
		lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
		lang: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		company: new FormControl()
	});

	public updatePasswordForm = this.formBuilder.group({
		currentPassword: new FormControl('', [Validators.required]),
		newPassword: new FormControl('', [Validators.required, Validators.minLength(4)]),
		repeatNewPassword: new FormControl('', [Validators.required, Validators.minLength(4)])
	});

	public enable2faForm = this.formBuilder.group({
		token: new FormControl('', [Validators.required, Validators.minLength(6)])
	});

	readonly isSubmitting$ = new BehaviorSubject<boolean>(false);

	constructor(
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public dialogRef: MatDialogRef<AccountComponent>
	) {
		if (authService.loggedInUser$.value) {
			this.firstName.setValue(authService.loggedInUser$.value.profile.firstName);
			this.lastName.setValue(authService.loggedInUser$.value.profile.lastName);
			this.lang.setValue(authService.loggedInUser$.value.profile.lang);
			this.email.setValue(authService.loggedInUser$.value.profile.email);
			this.company.setValue(authService.loggedInUser$.value.profile.company);
			this.totpSharedSecret$ = authService.getTotpSharedSecret();
			this.totpSharedSecret$.subscribe(sharedSecret => {
				this.sharedSecret = sharedSecret.shared_secret;
				// We can create a QRCode once we've have fetched the totp
				// shared secret. The 'otpauth://totp/...' is already provided
				// in the fetched shared secret as 'shared_secret_url'. We now
				// need to feed this url to a QRCode generator which will
				// deliver a image data string that can set to the a html img
				// tag like this `<img src="{{qrCodeUrl}}">`.
				QRCode.toDataURL(sharedSecret.shared_secret_url, (err, dataUrl) => {
					this.qrCodeUrl = dataUrl;
					});
			}, err => {
				console.log(err);
			});
			this.totpEnabled = authService.loggedInUser$.value.profile.totpEnabled;
		}
	}

	// convenience getters for easy access to form fields
	get firstName() { return this.updateAccountForm.get('firstName'); }
	get lastName() { return this.updateAccountForm.get('lastName'); }
	get lang() { return this.updateAccountForm.get('lang'); }
	get email() { return this.updateAccountForm.get('email'); }
	get company() { return this.updateAccountForm.get('company'); }

	// convenience getters for easy access to change password form fields
	get currentPassword() { return this.updatePasswordForm.get('currentPassword'); }
	get newPassword() { return this.updatePasswordForm.get('newPassword'); }
	get repeatNewPassword() { return this.updatePasswordForm.get('repeatNewPassword'); }

	// convenience getters for easy access to 2FA form fields
	get token() { return this.enable2faForm.get('token'); }


	async onSubmit() {
		this.serverErrorMessage = new Array<string>();
		this.isSubmitting$.next(true);

		try {
			await this.authService.updateAccount(
				this.firstName.value,
				this.lastName.value,
				this.lang.value,
				this.email.value,
				this.company.value);
			this.dialogRef.close();
		} catch (e) {
			JSON.parse(e.error).errors.forEach(err => {
				this.serverErrorMessage.push(err.msg);
			});
			if (e.status === 409) {
				this.email.setErrors({ taken: true });
			} else if (e.status === 422) {
				JSON.parse(e.error).errors.forEach(err => {
					this.serverErrorMessage.push(err.msg);
				});
			} else {
				if (e.status === 504) {
					this.serverErrorMessage.push('Unable to connect to storro.com');
				} else {
					this.serverErrorMessage.push('Something went wrong. Contact us if this happens again.');
				}
			}
		} finally {
			this.isSubmitting$.next(false);
		}
	}

	// The update password button has been pressed.
	async onChangePassword() {
		this.serverErrorMessage = new Array<string>();

		if (!this.currentPassword.valid) {
			this.serverErrorMessage.push('Current password is required.');
		} else if (!this.newPassword.valid) {
			this.serverErrorMessage.push('Password must be longer then 4 characters.');
		} else if (this.repeatNewPassword.value !== this.newPassword.value) {
			this.serverErrorMessage.push('Repeated password not equal.');
		}

		if (this.serverErrorMessage.length === 0) {
			this.isSubmitting$.next(true);

			try {
				await this.authService.updateAccountPassword(
					this.currentPassword.value,
					this.newPassword.value,
					this.repeatNewPassword.value);
				this.dialogRef.close();
			} catch (e) {
				if (e.status === 504) {
					this.serverErrorMessage.push('Unable to connect to storro.com');
				} else if (e.status === 422) {
					JSON.parse(e.error).errors.forEach(err => {
						this.serverErrorMessage.push(err.msg);
					});
				} else {
					this.serverErrorMessage.push(e.error);
				}
			} finally {
				this.isSubmitting$.next(false);
			}
		}
	}

	async onEnable2fa() {
		this.serverErrorMessage = new Array<string>();
		if (!this.token.valid) {
			this.serverErrorMessage.push('Please enter the authentication code.');
		} else {
			try {
				await this.authService.enable2fa(this.token.value);
				this.dialogRef.close();
			} catch (e) {
				if (e.status === 504) {
					this.serverErrorMessage.push('Unable to connect to storro.com');
				} else if (e.status === 422) {
					JSON.parse(e.error).errors.forEach(err => {
						this.serverErrorMessage.push(err.msg);
					});
				} else {
					this.serverErrorMessage.push(e.error);
				}
			}
		}
	}

	async onDisable2fa() {
		this.serverErrorMessage = new Array<string>();
		try {
			await this.authService.disable2fa();
			this.dialogRef.close();
		} catch (e) {
			if (e.status === 504) {
				this.serverErrorMessage.push('Unable to connect to storro.com');
			} else if (e.status === 422) {
				JSON.parse(e.error).errors.forEach(err => {
					this.serverErrorMessage.push(err.msg);
				});
			} else {
				this.serverErrorMessage.push(e.error);
			}
		}
	}
}
