import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { PodService } from '../pod.service';

@Component({
	selector: 'app-pod-indicator',
	templateUrl: './indicator.component.html',
	styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {

	@Input() podName: string;

	podIsOnline$: Observable<boolean>;
	constructor(private podService: PodService) {
	}

	async ngOnInit() {
		this.podIsOnline$ = this.podService.podAvailable(this.podName);
	}
}
