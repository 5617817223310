import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AdminListItem } from '../admins/admins.service';
import { PeerConnectionState } from '../pods/pod.service';

// Data format for the returned JSON from /api/realms.
export interface RealmListItem {
	id: number;
	name: string;
}

export interface RealmDetails {
	id: string;
	name: string;
	pod: string;
	psp: Array<string>;
}

// Data format array item of /api/realms/{id}/invitations
export interface RealmAdminInvitationItem {
	id: number;
	authorId: number;
	second_secret: string;
	created: Date;
	expires: Date;
	email_invitee: string;
	accepted_by: number|undefined;
	accepted_on: Date|undefined;
}

export interface UserListItem {
	id: number;
	name: string;
	email: string;
	isRegistered: boolean;
	is_guest: boolean;
}

export interface Device {
	connection_state: string;
	last_seen: Date;
	name: string;
	operating_system: string;
	peer_key: string;
	removed: boolean;
	version: string;
}

export interface HashedDeviceToken {
	hashed_token: string;
	expiration_date: Date;
}

export interface DeviceToken {
	token: string;
	expiration_date: Date;
}

export interface UserDetails {
	id: number;
	name: string;
	email: string;
	isRegistered: boolean;
	is_guest: boolean;
	devices: Array<Device>;
	hashed_device_tokens: Array<HashedDeviceToken>;
	user_public_key: string;
}

export interface EventLogFrame {
	description: string;
	location: string;
	parameters: Object;
}

export interface EventLog {
	category: string;
	frames: Array<EventLogFrame>;
	severity: string;
}

export interface EventLogs {
	[timestamp: string]: EventLog;
}

export interface UserCount {
	guest_count: number;
	has_max_guests: boolean;
	max_guests: number;
	max_guests_reached: boolean;
	max_users: number;
	max_users_reached: boolean;
	user_count: number;
}

/**
 * A Service for working with Realms from the Storro API
 */
@Injectable({
	providedIn: 'root'
})
export class RealmService {
	constructor(private http: HttpClient, private titleService: Title) {
	}

	realmIdFromPath$: BehaviorSubject<string|undefined> =
		new BehaviorSubject<string|undefined>(undefined);
	realmFromPath$: BehaviorSubject<RealmDetails | undefined> =
		new BehaviorSubject<RealmDetails | undefined>(undefined);

	public realmIdFromRoute(route: ActivatedRouteSnapshot): string|undefined {
		if (route.paramMap.has('realmId')) {
			return route.paramMap.get('realmId');
		} else {
			return undefined;
		}
	}

	public realm(realmId: string|undefined): Observable<RealmDetails | undefined> {
		if (realmId) {
			return this.http.get<RealmDetails>(`/api/realms/${realmId}`);
		} else {
			return undefined;
		}
	}

	public realms(): Observable<Array<RealmListItem>> {
		return this.http.get<Array<RealmListItem>>('/api/realms');
	}

	public async createRealm(realmName: string, billingCustomerID: string): Promise<RealmListItem> {
		const realm = await this.http.post<RealmListItem>(
			'/api/realms', { name: realmName, billingCustomerID }).toPromise();
		return realm;
	}

	public realmFromPathId(): string {
		if (!this.realmIdFromPath$.value) {
			throw Error('No realm selected.');
		}
		return this.realmIdFromPath$.value;
	}

	// Return all admins of the given realm
	public admins(): Observable<Array<AdminListItem>> {
		return this.http.get<Array<AdminListItem>>(
			`/api/realms/${this.realmFromPathId()}/admins`);
	}

	// Return all admin invitations of the given realm
	public adminInvitations(): Observable<Array<RealmAdminInvitationItem>> {
		return this.http.get<Array<RealmAdminInvitationItem>>(
			`/api/realms/${this.realmFromPathId()}/invitations`);
	}

	/**
	 * Invite an administrator to the given Realm. This method
	 * will throw on failure. The invitee can use the email to login
	 * with an already existing account or create a new account.
	 * @param emailAddress The email address where the invitation is sent to
	 */
	public async inviteAdmin(emailAddress: string): Promise<void> {
		await this.http.put(`/api/realms/${this.realmFromPathId()}/invitations`, {
			email: emailAddress,
			url: `${window.location.origin}/join`,
			second_factor: undefined
		}).toPromise();
	}

	public async removeAdminInvitation(adminId: number): Promise<void> {
		await this.http.delete(`/api/realms/${this.realmFromPathId()}/invitations/${adminId}`).toPromise();
	}

	/**
	 * Returns a list of users.
	 */
	public users(): Observable<Array<UserListItem>> {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.get<Array<UserListItem>>(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users`);
	}

	// Returns the amount of users, guests and their limits.
	public userCountStatistics(): Observable<UserCount> {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.get<UserCount>(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/statistics/count`);
	}


	/**
	 * Returns the realm configuration.
	 */
	public config(): Observable<any> {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.get<any>(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/config`
		);
	}

	/**
	 * Returns a list of versions available.
	 */
	public versions(): Observable<Array<string>> {
		return this.http.get<Array<string>>(`https://binaries.storro.com/versions.json`);
	}

	/**
	 * Returns details about a User
	 */
	public user(userId: string): Observable<UserDetails> {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.get<UserDetails>(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}`);
	}

	/**
	 * Updates the current version on the realm pod.
	 */
	public async updateCurrentVersion(selectedVersion: string) {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error("No Realm selected");
		}

		return this.http.put(
			`/api/realms/${realm.id}/pods/${realm.pod}/config/version`,
			{ "current_version": selectedVersion }
		).toPromise();
	}

	/**
	 * Create a user.
	 */
	public async createUser(fullName: string, emailAddress: string, company: string, description: string) {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.put(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users`,
			{ name: fullName, email: emailAddress, company: company, description: description }
		).toPromise();
	}

	/**
	 * Remove a user.
	 */
	public async removeUser(userId: number) {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.delete(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}`
		).toPromise();
	}

	public async removeDevice(userId: number, devicePeerKey: string) {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.delete(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/devices/${devicePeerKey}`
		).toPromise();
	}

	public async removeDeviceToken(userId: number, tokenHash: string) {
		const realm = this.realmFromPath$.value;
		if(!realm) {
			throw Error('No realm selected.');
		}

		return this.http.delete(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/device_tokens/${tokenHash}`
		).toPromise();
	}

	/**
	 *
	 * Promote guest user to member status.
	 */
	public async promoteUser(userId: number) {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error("No realm selected.");
		}

		return this.http.put(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/promote`,
			{}
		).toPromise();
	}

	/**
	 * regenerate the registration code for the member.
	 */
	public async regenerateRegistrationCode(userId: number) {
		const realm = this.realmFromPath$.value;
		if(!realm) {
			throw Error("No realm selected.");
		}

		return this.http.put(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/regenerate_token`,
			{}
		).toPromise();
	}

	/**
	 *
	 * generate an device registration token at the realm.
	 */
	public generateAccountRecoveryCode(userId: number, removeDevices: boolean): Observable<DeviceToken> {
		const realm = this.realmFromPath$.value;
		if(!realm) {
			throw Error("No realm selected.");
		}

		return this.http.post<DeviceToken>(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/devices/generate_account_recovery_code`,
			{
				remove: removeDevices
			}
		);
	}

	public emailToken(userId: number, token: DeviceToken) {
		const realm = this.realmFromPath$.value;
		if(!realm) {
			throw Error("No realm selected.");
		}

		return this.http.post(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/devices/email_account_recovery_code`,
			{ device_token: token }
		).toPromise();
	}

	// Get the network state of a given peerKey from the Realm Node
	public peerNetworkState(peerKey: string): Observable<PeerConnectionState> {
		const realm = this.realmFromPath$.value;
		if (!realm) {
			throw Error('No realm selected.');
		}
		return this.http.get<PeerConnectionState>(
			`/api/realms/${realm.id}` +
			`/pods/${realm.pod}/command/network` +
			`/peers/${peerKey}/state`);
	}

	public eventLogs(): Observable<EventLogs> {
		const realm = this.realmFromPath$.value;
		return this.http.get<EventLogs>(`/api/realms/${realm.id}/pods/${realm.pod}/command/eventlog`);
	}

	public userEventLogs(userId: number, devicePeerKey: string): Observable<EventLogs> {
		const realm = this.realmFromPath$.value;
		return this.http.get<EventLogs>(
			`/api/realms/${realm.id}/pods/${realm.pod}/command/users/${userId}/devices/${devicePeerKey}/log`
		);
	}

	public renameRealm(newName: string) {
		return this.http.put(`/api/realms/${this.realmFromPathId()}`, {name: newName}).toPromise();
	}
}
