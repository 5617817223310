import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from './reset_password.validators';

// The page that is shown when the admin invitation
// button is clicked in an email.

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset_password.component.html',
	styleUrls: ['./reset_password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	public passwordResetForm = this.formBuilder.group({
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
		confirmPassword: new FormControl('', [Validators.required]),
		second_factor: new FormControl(undefined, [])
	}, {
		// check whether our password and confirm password match
		validator: MustMatch('password', 'confirmPassword')
	});

	public serverErrorMessage: string;

	// The fullName of the user, composed from firstName and lastName
	public fullName: string;

	// The token that is part of the email url.
	private secret: string;

	// An error message that can be set on
	// init or when the button is clicked.
	public errorMsg: string;

	// To show the throbber.
	public isLoading: boolean;

	// To show success.
	public isSuccess: boolean;

	public requireSecondFactor: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		public authService: AuthService) {
		this.isSuccess = false;
		this.isLoading = true;
		this.secret = this.route.snapshot.queryParams['token'];
		this.requireSecondFactor = this.isSecondFactorRequired(this.route.snapshot.queryParams['2fa']);
		if (this.requireSecondFactor)
			this.passwordResetForm.controls['second_factor'].setValidators([Validators.required]);
	}

	private isSecondFactorRequired(secondFA: string|null)
	{
		return (secondFA && secondFA === 'y');
	}

	get password() { return this.passwordResetForm.get('password'); }
	get confirmPassword() { return this.passwordResetForm.get('confirm-password'); }

	get second_factor() { return this.passwordResetForm.get('second_factor'); }

	ngOnInit() {
		this.authService.passwordResetDetails(this.secret).subscribe(result => {
			this.fullName = `${result.firstName} ${result.lastName}`;
			this.isLoading = false;
		}, err => {
			this.setError(err.status, err.error);
			this.isLoading = false;
		});
	}

	setError(errorCode: number, errorString: string) {
		if (errorCode === 404) { // Not Found
			this.errorMsg = errorString;
		} else if (errorCode === 409) { // Conflict
			this.errorMsg = errorString;
		} else if (errorCode === 410) { // Gone
			this.errorMsg = errorString;
		} else if (errorCode === 500) { // Internal error
			this.errorMsg = 'Internal server error';
		} else if (errorCode === 504) { // Gateway error
			this.errorMsg = 'Failed to connect to the server';
		}
	}

	// The submit button is clicked.
	async onSubmit() {
		if (!this.passwordResetForm.valid) {
			console.error('Invalid signup form');
			return;
		}

		try {
			this.isLoading = true;
			await this.authService.resetAdminPassword(this.password.value, this.secret, this.second_factor.value);
			this.isSuccess = true;
		} catch (e) {
			this.setError(e.status, e.error);
		}

		this.isLoading = false;
	}

	routeToLogin() {
		this.router.navigate(['login']);
	}
}
