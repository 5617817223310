import { Component, Input } from '@angular/core';
import { PodItem } from '../../pod.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
	selector: 'app-storage-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.scss']
})
export class StoreListItemComponent {

	@Input() public podItem: PodItem;

	public loggedUser$ = this.authService.loggedInUser$;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService) {
	}

	open() {
		this.router.navigate(
			[this.podItem.name],
			{relativeTo: this.route});
	}
}
