import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { RealmService } from '../../realm/realm.service';
import { PodItem, PodService } from '../pod.service';

@Component({
		selector: 'app-storage-pod-usage',
		templateUrl: './storage-pod-usage.component.html',
		styleUrls: ['./storage-pod-usage.component.scss']
})
export class StoragePodUsageComponent implements OnInit {

	@Input() public podItem: PodItem;

	data: any[];
	colorScheme = {
		domain: ['#E53935', '#3CA159']
	};

	readonly loading = new BehaviorSubject<boolean>(true);
	public limit = 0.0;
	public used = 0.0;
	public available = 0.0;
	public limitExceeded = false;

	constructor(
		private podService: PodService,
		private realmService: RealmService,
	) {}

	ngOnInit(): void {
		try {
			combineLatest(
				this.realmService.config(),
				this.podService.contentStorageUsedByPodId(this.podItem.name),
				(conf, storageUse) => ({conf, storageUse}))
			.subscribe(pair => {
				this.loading.next(false);

				this.limit = pair.conf.storage_limit;
				this.used = pair.storageUse.storage_used;
				this.available = this.limit - this.used;
				let fraction = 0;
				if (this.used > this.limit) {
					this.limitExceeded = true;
					fraction = 1;
				} else {
					this.limitExceeded = false;
					fraction = this.used / this.limit;
				}

				this.data = [
					{
						name: 'Used',
						value: fraction
					}, {
						name: 'Available',
						value: 1.0 - fraction
					}
				];
				Object.assign(this, this.data);
			});
		} catch (err) {
		}
	}

	tooltipText(val: any): string {
		return (val.value * 100) + '% ' + val.data.label;
	}
}
