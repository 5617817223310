import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Data format for an Admin list item.
export interface AdminListItem {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	isSuperAdmin: boolean;
}

// This class is all about managing administrators.

@Injectable({
	providedIn: 'root'
})
export class AdminsService {
	constructor(private http: HttpClient) {
	}

	// Returns all admins when you're a 'Super Admin', otherwise it will
	// return only return yourself.
	public admins(): Observable<Array<AdminListItem>> {
		return this.http.get<Array<AdminListItem>>('/api/admins');
	}
}
