import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RealmService } from '../../realm.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-invite',
	templateUrl: './invite.component.html',
	styleUrls: ['./invite.component.scss']
})
export class InviteUserComponent {

	public inviteUserForm = new FormGroup({
		emailAddress: new FormControl('', [Validators.required, Validators.email]),
		fullName: new FormControl('', [Validators.required, Validators.minLength(2)]),
		company: new FormControl(''),
		description: new FormControl('')
	});

	public errorMessage: string;
	public isBusy = false;

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<InviteUserComponent>) { }

	async submit() {
		try {
			this.isBusy = true;
			await this.realmService.createUser(
				this.fullNameCtrl.value,
				this.emailAddressCtrl.value,
				this.companyCtrl.value,
				this.descriptionCtrl.value);
			this.isBusy = false;
			this.dialogRef.close();
		} catch (e) {
			this.isBusy = false;
			this.errorMessage = e.error;

			// @todo Implement gui feedback
			console.error(e);
		}
	}

	close() {
		this.dialogRef.close();
	}

	// convenience getters for easy access to form fields
	get emailAddressCtrl() { return this.inviteUserForm.get('emailAddress'); }
	get fullNameCtrl() { return this.inviteUserForm.get('fullName'); }
	get companyCtrl() { return this.inviteUserForm.get('company'); }
	get descriptionCtrl() { return this.inviteUserForm.get('description'); }
}
