import { Component } from '@angular/core';
import { RealmService } from '../realm.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * A dialog component for sending a new admin invitation.
 */
@Component({
	selector: 'app-invite-admin',
	templateUrl: './invite-admin.component.html',
	styleUrls: ['./invite-admin.component.scss']
})
export class InviteAdminComponent {

	public inviteAdminForm = new FormGroup({
		emailAddress: new FormControl('', [Validators.required, Validators.email]),
	});

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<InviteAdminComponent>) { }

	async submit() {
		try {
			await this.realmService.inviteAdmin(this.emailAddressCtrl.value);
			this.dialogRef.close();
		} catch (e) {
			// @todo Implement gui feedback
			console.error(e);
		}
	}

	close() {
		this.dialogRef.close();
	}

	// convenience getter for easy access to form fields
	get emailAddressCtrl() { return this.inviteAdminForm.get('emailAddress'); }
}
