import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { LicenseProduct, PaymentService, ProductBase, StorageProduct } from '../payment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SinglePageWrapperService } from '../../single-page-wrapper/single-page-wrapper.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { RealmService } from '../../realm/realm.service';

declare const Chargebee;

registerLocaleData(localeNl, 'nl');

/**
 * The filterd product; or the selected one from the list; with the price parsed
 */
interface ProductFiltered extends Pick<ProductBase, 'id' | 'periodUnit'> {
	price: number;
}

export const DEFAULT_LICENSE_AMOUNT = 4;

@Component({
	selector: 'app-payment',
	templateUrl: './purchase-form.component.html',
	styleUrls: ['./purchase-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})

export class PurchaseFormComponent implements OnInit, OnDestroy {
	serverErrorMessage: string;
	licenseProductsRaw: LicenseProduct[];
	licenseProducts: ProductFiltered[];
	storageProducts: StorageProduct[];
	selectedLicense: ProductFiltered;
	selectedStorage: ProductFiltered;
	trialEndDate = this.calculateTrialEndData();
	state: 'billing-form' | 'create-realm' = 'billing-form';

	readonly isSubmitting$ = new BehaviorSubject<boolean>(false);
	readonly loaded$ = new BehaviorSubject<boolean>(false);
	readonly unSubscriber$ = new Subject<void>();

	public userForm = new FormGroup({
		realmName: new FormControl('', [Validators.required]),
		userAmountInput: new FormControl(DEFAULT_LICENSE_AMOUNT, [
			Validators.required,
			Validators.min(1),
			Validators.pattern(/^[0-9]+$/),
		]),
		billingPeriod: new FormControl('month'),
		additionalStorage: new FormControl(0, [
			Validators.required,
			Validators.min(0),
			Validators.pattern(/^[0-9]+$/),
		]),
	});

	get userAmount(): number {
		return this.userForm.get('userAmountInput').value > 0 ? this.userForm.get('userAmountInput').value : 0;
	}

	get freeStorageAmount(): number {
		return this.userAmount * 500;
	}

	get additionalStorageAmount(): number {
		return this.userForm.get('additionalStorage').value > 0 ? this.userForm.get('additionalStorage').value : 0;
	}

	get totalStorageAmount(): number {
		return this.freeStorageAmount + (this.additionalStorageAmount * 1000);
	}

	get billingPeriod(): string {
		return this.userForm.get('billingPeriod').value;
	}

	get billingPeriodFriendlyName(): string {
		return this.billingPeriod === 'month' ? 'monthly' : 'yearly';
	}

	get realmName(): string {
		return this.userForm.get('realmName').value;
	}

	get totalLicensePrice(): number {
		return this.selectedLicense.price / 100 * this.userAmount
	}

	get totalStoragePrice(): number {
		return this.additionalStorageAmount * (this.selectedStorage.price / 100);
	}

	constructor(
		private paymentService: PaymentService,
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private singlePageWrapperService: SinglePageWrapperService,
		private changeDetectorRef: ChangeDetectorRef,
		private realmService: RealmService,
		private zone: NgZone,
	) {
	}

	ngOnInit() {
		Chargebee.init({
			site: environment.chargeBeeSiteName,
			enableGTMTracking: true,
		});

		this.paymentService.getProducts().pipe(
			tap(() => this.loaded$.next(true)),
			tap(({license_products, storage_products}) => {
				this.licenseProductsRaw = license_products;
				this.storageProducts = storage_products;

				// set the filtered products so we can show them on screen
				if (this.storageProducts.length > 0 && this.licenseProductsRaw.length > 0) {
					this.setFilteredProducts();
				} else {
					this.serverErrorMessage = 'We cannot load our products at this time. Please try again later or contact our support.';
				}
			})
		).subscribe();

		this.authService.account.pipe(
			tap(user => this.userForm.get('realmName').patchValue(user.profile.company)),
			// enable the back button for the SinglePageWrapper
			tap(user => {
				if (user.realms.count > 0) {
					this.singlePageWrapperService.setBackRoute('/realms');
				}
			}),
		).subscribe();

		// filter the products when the billing period is changed
		this.userForm.get('billingPeriod').valueChanges.pipe(
			distinctUntilChanged(),
			tap(() => this.setFilteredProducts()),
			takeUntil(this.unSubscriber$),
		).subscribe();

		// filter the products when the user amount is changed
		this.userForm.get('userAmountInput').valueChanges.pipe(
			distinctUntilChanged(),
			filter(value => value > 0),
			tap(() => this.setFilteredProducts()),
			takeUntil(this.unSubscriber$),
		).subscribe();
	}

	ngOnDestroy() {
		this.unSubscriber$.next();
		this.unSubscriber$.complete();
	}

	/**
	 * get the license product based on the given qty and
	 * set the license / storage product based on the period
	 */
	setFilteredProducts(): void {
		// set the correct price for the license based on the user amount
		this.licenseProducts = this.licenseProductsRaw.map(product => {
			const productPrice = product.prices.find(price => {
				return (
					this.userAmount >= price.startUnit && this.userAmount <= price.endUnit
				) || (
					this.userAmount >= price.startUnit && price.endUnit === undefined
				);
			});

			return {
				id: product.id,
				price: productPrice.price,
				periodUnit: product.periodUnit,
			}
		});

		// set the selected products
		this.selectedLicense = this.licenseProducts.find(product => product.periodUnit === this.billingPeriod);
		this.selectedStorage = this.storageProducts.find(product => product.periodUnit === this.billingPeriod);
	}

	/**
	 * When clicking/submitting the page we should create a new hostedPageObject by sending the data to the server
	 * With the response we can open the checkout on the Chargebee object
	 *
	 * When the checkout has been completed, send the user to the create realm page
	 */
	async onSubmit() {
		this.isSubmitting$.next(true);
		this.serverErrorMessage = '';

		this.paymentService.checkout(
			this.selectedLicense.id,
			this.userAmount,
			this.selectedStorage.id,
			this.additionalStorageAmount,
		).subscribe(hostedPage => {
			const chargebeeInstance = Chargebee.getInstance();
			chargebeeInstance.openCheckout({
				hostedPage: () => {
					return Promise.resolve(hostedPage);
				},
				success: hostedPageId => {
					this.paymentService.getCustomerIdByHostedPageId(hostedPageId).subscribe(billingCustomerID => {
						// TODO what to do when we cannot find te hosted_PAGE ID? This is a rare case but might be possible somehow

						// close all modals
						chargebeeInstance.closeAll();

						// Create the realm
						// Run inside a zone because we are trigger the router with this call from outside angular self
						this.zone.run(() => this.createRealm(billingCustomerID));
						this.changeDetectorRef.detectChanges();
					});
				},
				close: () => {
					this.isSubmitting$.next(false);
					this.changeDetectorRef.detectChanges();
				},
			});
		}, () => {
			this.isSubmitting$.next(false);
			this.serverErrorMessage = 'We cannot process your request at this time. Please try again later or contact our support.';
		});
	}

	async createRealm(billingCustomerID: string): Promise<void> {
		this.state = 'create-realm';

		const realm = await this.realmService.createRealm(
			this.realmName,
			billingCustomerID,
		);

		this.router.navigate(['realms', realm.id]);
	}

	/**
	 * set the trial date in unix timestamp, 30 days from now
	 */
	private calculateTrialEndData(): number {
		const trialDays = 30;
		const trialEndDate = new Date();

		trialEndDate.setDate(trialEndDate.getDate() + trialDays);

		return trialEndDate.getTime();
	}
}
