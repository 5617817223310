import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Observable, empty, of, BehaviorSubject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { UserListItem, RealmService, UserCount } from '../realm.service';
import { InviteUserComponent } from './invite/invite.component';
import { UserEventLogsComponent } from './user_event_logs/event_logs.component';
import { MatDialog } from '@angular/material/dialog';
import { catchError, finalize, filter } from 'rxjs/operators';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

	public usersDataSource: UsersDataSource;
	public guestsDataSource: UsersDataSource;
	public userCount$: Observable<UserCount>;

	constructor(
		private realmService: RealmService,
		private dialog: MatDialog) {
		}

	ngOnInit() {
		this.usersDataSource = new UsersDataSource(this.realmService, true, false);
		this.guestsDataSource = new UsersDataSource(this.realmService, false, true);
		try {
			this.userCount$ = this.realmService.userCountStatistics();
			this.usersDataSource.loadUsers();
			this.guestsDataSource.loadUsers();
		} catch (err) {
		}
	}

	public openDialogInviteUser() {
		const dialogRef = this.dialog.open(InviteUserComponent);
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}

	public openEventLogs(user: UserListItem) {
		const dialogRef = this.dialog.open(UserEventLogsComponent, {
			data: {
				userId: user.id
			},
			autoFocus: false
		});

		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}
}

/**
 * A data source to display users.
 */
export class UsersDataSource extends DataSource<UserListItem> {
	private usersSubject = new BehaviorSubject<UserListItem[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	public loading$ = this.loadingSubject.asObservable();

	// Columns to display
	columns = ['name', 'email', 'company', 'isRegistered'];

	constructor(
		private realmService: RealmService,
		private displayUsers: boolean,
		private displayGuest: boolean
		) {
		super();
	}

	loadUsers() {
		this.loadingSubject.next(true);
		this.realmService.users()
		.pipe(catchError(() => of([])), finalize(() => this.loadingSubject.next(false)))
		.subscribe(users => this.usersSubject.next(users.filter(user => {
			if (this.displayGuest && user.is_guest) {
				return true;
			} else if (this.displayUsers && !user.is_guest) {
				return true;
			} else {
				return false;
			}
		})));
	}

	connect(collectionViewer: CollectionViewer): Observable<Array<UserListItem>> {
		return this.usersSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer) {}
}
