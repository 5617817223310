import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

/** The page that is shown when the user
* forgets their password
* button is clicked sends an email to reset
* the password.
*/
@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot_password.component.html',
	styleUrls: ['./forgot_password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	public forgotPasswordForm = this.formBuilder.group({
		email: new FormControl('', [Validators.required, Validators.email])
	});

	// An error message that can be set on
	// init or when the button is clicked.
	public errorMsg: string;

	// To show the throbber.
	public isLoading: boolean;

	// To show success message.
	public isSuccess: boolean;
	constructor(
			private formBuilder: FormBuilder,
			private authService: AuthService,
			private route: ActivatedRoute,
			private router: Router) {
		this.isLoading = true;
	}

	ngOnInit() {
		this.isLoading = false;
		this.isSuccess = false;
	}

	get email() { return this.forgotPasswordForm.get('email'); }

	setError(errorCode: number, errorString: string) {
		if (errorCode === 500) { // Internal error
			this.errorMsg = 'Internal server error';
		} else if (errorCode === 504) { // Gateway error
			this.errorMsg = 'Failed to connect to the server';
		}
	}

	// The send button is clicked.
	async onSubmit() {
		if (!this.forgotPasswordForm.valid) {
			console.error('Invalid form');
			return;
		}

		try {
			this.isLoading = true;
			await this.authService.forgotAdminPassword(this.email.value, undefined);
			this.isSuccess = true;
		} catch (e) {
			this.setError(e.status, e.error);
		}

		this.isLoading = false;
	}

	routeToLogin() {
		this.router.navigate(['/login']);
	}
}
