import { Component, Inject } from '@angular/core';
import { RealmService, UserDetails, DeviceToken } from '../../realm.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
	selector: 'app-account-recovery',
	templateUrl: './account_recovery.component.html',
	styleUrls: ['./account_recovery.component.scss']
})
export class AccountRecoveryComponent {
	public tokenRequested = false;
	public errorMessage: string;
	public isBusy = false;
	public emailSuccess = false;
	public emailFailure = false;
	public removeDevices = false;
	public user: UserDetails;
	public token$: Observable<DeviceToken>;

	constructor(
		private realmService: RealmService,
		public dialogRef: MatDialogRef<AccountRecoveryComponent>, @Inject(MAT_DIALOG_DATA) private data: any)
	{
		this.user = data.user;
	}

	public setDeviceRemoved(removeDevices: boolean)
	{
		this.removeDevices = removeDevices;
	}

	public async submit()
	{
		this.tokenRequested = true;
		this.token$ = this.realmService.generateAccountRecoveryCode(this.user.id, this.removeDevices);
	}

	async emailToken(token: DeviceToken) {
		this.isBusy = true;
		try {
			await this.realmService.emailToken(this.user.id, token);
			this.emailSuccess = true;
		}catch(e) {
			this.errorMessage = e;
			this.emailFailure = true;
		}
		this.isBusy = false;
	}

	public expirationDuration(expires: Date) {
		return moment(expires).fromNow();
	}

	close() {
		this.dialogRef.close(false);
	}
}
