import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from './auth/auth.http.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { JoinComponent } from './auth/join/join.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthGuard, Roles } from './auth/auth.guard';
import { NavigationComponent } from './navigation/navigation.component';
import { ListComponent } from './realm/list/list.component';
import { RealmGuard } from './realm/realm.guard';
import { DashboardComponent } from './realm/dashboard/dashboard.component';
import { AdminsComponent } from './realm/admins/admins.component';
import { PodsComponent } from './pods/pods.component';
import { InviteAdminComponent } from './realm/invite-admin/invite-admin.component';
import { ColorPipe } from './color.pipe';
import { FileSizePipe } from './filesize.pipe';
import { MomentModule } from 'ngx-moment';
import { LandingComponent } from './landing/landing.component';
import { AdminListComponent } from './admins/list/list.component';
import { ErrorComponent } from './auth/error/error.component';
import { ResetPasswordComponent } from './auth/reset_password/reset_password.component';
import { ForgotPasswordComponent } from './auth/forgot_password/forgot_password.component';
import { IndicatorComponent } from './pods/indicator/indicator.component';
import { NetworkComponent } from './pods/network/network.component';
import { ConsoleLogsComponent } from './pods/console_logs/console_logs.component';
import { EventLogsComponent } from './pods/event_logs/event_logs.component';
import { EventFramesComponent } from './pods/event_logs/event_frames/event_frames.component';
import { PodGuard } from './pods/pod.guard';
import { ProjectsComponent } from './pods/projects/projects.component';
import { AccountComponent } from './auth/account/account.component';
import { BootstrapComponent } from './realm/bootstrap/bootstrap.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { StorageComponent } from './pods/storage/storage.component';
import { DownloadsComponent } from './pods/projects/downloads/downloads.component';
import { StoreListItemComponent } from './pods/storage/list-item/list-item.component';
import { StoragePodComponent } from './pods/storage-pod/storage-pod.component';
import { UsersComponent } from './realm/users/users.component';
import { InviteUserComponent } from './realm/users/invite/invite.component';
import { RemoveUserComponent } from './realm/users/remove/remove.component';
import { RemoveDeviceComponent } from './realm/users/remove_device/remove_device.component';
import { RemoveDeviceTokenComponent } from './realm/users/remove_device_token/remove_device_token.component';
import { PromoteUserComponent } from './realm/users/promote/promote.component';
import { RegenerateCodeUserComponent } from './realm/users/regen_code/regen_code.component';
import { UserEventLogsComponent } from './realm/users/user_event_logs/event_logs.component';
import { UserEventFramesComponent } from './realm/users/user_event_logs/user_event_frames/event_frames.component';
import { AccountRecoveryComponent } from './realm/users/account_recovery/account_recovery.component';
import { NgBytesPipeModule } from 'angular-pipes';
import { PodComponent } from './pods/pod/pod.component';
import { BinariesComponent } from './binaries/binaries.component';
import { UserComponent } from './realm/users/user/user.component';
import { DeviceIndicatorComponent } from './realm/users/device-indicator/device-indicator.component';
import { MessageBoxComponent } from './message_box/message_box.component';
import { ConfigComponent } from './realm/config/config.component';
import { PurchaseFormComponent } from './purchase/purchase-form/purchase-form.component';
import { BillingComponent } from './realm/billing/billing.component';
import { StoragePodUsageComponent } from './pods/storage-pod-usage/storage-pod-usage.component';
import { StatsComponent } from './realm/users/stats/stats.component';
import { RenameRealmComponent } from './realm/config/rename-realm/rename-realm.component';
import { LoginTotpComponent } from './auth/login-totp/login-totp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from './shared/shared.module';
import { SinglePageWrapperComponent, DefaultInfoBlockComponent, SuccessBlockComponent } from './single-page-wrapper';
import { HasRealmGuard } from './realm/hasRealm.guard';
import { LandingGuard } from './landing/landing.guard';
import { NoAuthGuard } from './auth/noAuth.guard';

const appRoutes: Routes = [
	{
		path: 'binaries/:realmUuid',
		component: SinglePageWrapperComponent,
		children: [
			{
				path: '',
				component: BinariesComponent,
			},
		],
	},
	{
		path: 'login',
		component: SinglePageWrapperComponent,
		canActivate: [NoAuthGuard],
		children: [
			{
				path: '',
				component: LoginComponent,
			},
		],
	},
	{
		path: 'signup',
		component: SinglePageWrapperComponent,
		canActivate: [NoAuthGuard],
		children: [
			{
				path: '',
				component: SignupComponent,
			},
		],
	},
	{
		path: 'join',
		component: SinglePageWrapperComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				component: JoinComponent,
			},
		],
	},
	{
		path: 'reset_password',
		component: SinglePageWrapperComponent,
		canActivate: [NoAuthGuard],
		children: [
			{
				path: '',
				component: ResetPasswordComponent,
			},
		],
	},
	{
		path: 'forgot_password',
		component: SinglePageWrapperComponent,
		canActivate: [NoAuthGuard],
		children: [
			{
				path: '',
				component: ForgotPasswordComponent,
			},
		],
	},
	{
		path: 'verify',
		component: VerifyEmailComponent
	},
	{
		path: 'realms/purchase',
		component: SinglePageWrapperComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				component: PurchaseFormComponent,
			},
		],
	},
	{
		path: 'realms/:realmId/bootstrap',
		component: SinglePageWrapperComponent,
		canActivate: [AuthGuard],
		data: {
			component: SuccessBlockComponent,
		},
		children: [
			{
				path: '',
				component: BootstrapComponent,
				canActivate: [RealmGuard],
			},
		],
	},
	{
		path: '',
		component: NavigationComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				canActivate: [LandingGuard],
				component: LandingComponent,
			},
			{
				path: 'realms',
				component: ListComponent,
				canActivate: [HasRealmGuard],
			},
			{
				path: 'realms/:realmId',
				component: DashboardComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'realms/:realmId/admins',
				component: AdminsComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'realms/:realmId/pods',
				component: PodsComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'realms/:realmId/storage',
				component: StorageComponent,
				canActivate: [RealmGuard]
			},
			{
				path: 'realms/:realmId/users',
				component: UsersComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'realms/:realmId/users/:userId',
				component: UserComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'realms/:realmId/config',
				component: ConfigComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'realms/:realmId/pods/:podId',
				component: PodComponent,
				canActivate: [RealmGuard, PodGuard]
			},
			{
				path: 'realms/:realmId/storage/:podId',
				component: StoragePodComponent,
				canActivate: [RealmGuard, PodGuard],
			},
			{
				path: 'realms/:realmId/pods/:podId/network',
				component: NetworkComponent,
				canActivate: [RealmGuard, PodGuard],
			},
			{
				path: 'realms/:realmId/pods/:podId/console_logs',
				component: ConsoleLogsComponent,
				canActivate: [RealmGuard, PodGuard]
			},
			{
				path: 'realms/:realmId/pods/:podId/event_logs',
				component: EventLogsComponent,
				canActivate: [RealmGuard, PodGuard],
			},
			{
				path: 'realms/:realmId/storage/:podId/projects',
				component: ProjectsComponent,
				canActivate: [RealmGuard, PodGuard],
			},
			{
				path: 'realms/:realmId/billing',
				component: BillingComponent,
				canActivate: [RealmGuard],
			},
			{
				path: 'admins',
				component: AdminListComponent,
				canActivate: [AuthGuard],
				data: {roles: [Roles.SuperAdmin]},
			},
			{
				path: 'account',
				component: AccountComponent,
				canActivate: [AuthGuard],
			},
		],
	},
	{
		path: '403',
		component: SinglePageWrapperComponent,
		children: [
			{
				path: '',
				component: ErrorComponent,
				data: {code: 403, message: 'Forbidden'}
			},
		],
	},
	{
		path: 'error',
		component: SinglePageWrapperComponent,
		children: [
			{
				path: '',
				component: ErrorComponent,
				data: {code: 500, message: 'An unexpected error occurred.'}
			},
		],
	},
	{
		path: '**',
		component: SinglePageWrapperComponent,
		children: [
			{
				path: '',
				component: ErrorComponent,
				data: {code: 404, message: 'Page not found'}
			},
		],
	},
];

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		SignupComponent,
		JoinComponent,
		DashboardComponent,
		NavigationComponent,
		ListComponent,
		ColorPipe,
		FileSizePipe,
		AdminsComponent,
		InviteAdminComponent,
		PodsComponent,
		LandingComponent,
		ErrorComponent,
		AdminListComponent,
		ResetPasswordComponent,
		ForgotPasswordComponent,
		IndicatorComponent,
		NetworkComponent,
		ConsoleLogsComponent,
		EventLogsComponent,
		EventFramesComponent,
		ProjectsComponent,
		AccountComponent,
		BootstrapComponent,
		VerifyEmailComponent,
		StorageComponent,
		StoreListItemComponent,
		StoragePodComponent,
		UsersComponent,
		InviteUserComponent,
		RemoveUserComponent,
		RemoveDeviceComponent,
		RemoveDeviceTokenComponent,
		PromoteUserComponent,
		RegenerateCodeUserComponent,
		UserEventLogsComponent,
		UserEventFramesComponent,
		AccountRecoveryComponent,
		DownloadsComponent,
		PodComponent,
		BinariesComponent,
		UserComponent,
		DeviceIndicatorComponent,
		MessageBoxComponent,
		ConfigComponent,
		PurchaseFormComponent,
		BillingComponent,
		StoragePodUsageComponent,
		StatsComponent,
		RenameRealmComponent,
		LoginTotpComponent,
		SinglePageWrapperComponent,
		DefaultInfoBlockComponent,
		SuccessBlockComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		NgxChartsModule,
		HttpClientModule,
		LayoutModule,
		MatButtonModule,
		MatCardModule,
		MatChipsModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatTableModule,
		MatToolbarModule,
		MatTabsModule,
		MatSelectModule,
		MomentModule,
		NgOtpInputModule,
		ReactiveFormsModule,
		RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
		NgBytesPipeModule,
		MatRadioModule,
		MatSliderModule,
		FormsModule,
		SharedModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true,
		},
		{
			provide: 'googleTagManagerId',
			useValue: 'GTM-PDRXP9S',
		}
	],
	bootstrap: [AppComponent],
	entryComponents: [
		InviteAdminComponent,
		InviteUserComponent,
		RemoveUserComponent,
		RemoveDeviceComponent,
		RemoveDeviceTokenComponent,
		PromoteUserComponent,
		RegenerateCodeUserComponent,
		AccountRecoveryComponent,
		MessageBoxComponent,
		EventFramesComponent,
		UserEventLogsComponent,
		UserEventFramesComponent,
		DefaultInfoBlockComponent,
	],
})
export class AppModule { }
