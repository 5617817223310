import { Pipe, PipeTransform } from '@angular/core';
import ColorHash from 'color-hash';

/**
 * Can be used in templates to generate a color from
 * a string. I.e. 'some name' | color
 */
@Pipe({name: 'color'})
export class ColorPipe implements PipeTransform {
	transform(name: string): string {
		const colorHash = new ColorHash();
		return colorHash.hex(name);
	}
}
