import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PodService } from './pod.service';

/** Guard routes that require a selected pod. */
@Injectable({
	providedIn: 'root'
})
export class PodGuard implements CanActivate {

	constructor(
		private podService: PodService,
		private router: Router) {
	}

	private podIdFromRoute(route: ActivatedRouteSnapshot): string|undefined {
		if (route.paramMap.has('podId')) {
			return route.paramMap.get('podId');
		} else {
			return undefined;
		}
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
			: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		// Get the pod id from the url path.
		const podUuid = this.podIdFromRoute(next);
		if (this.podService.podIdFromPath$.value === podUuid && podUuid) {
			return true;
		}

		this.podService.podIdFromPath$.next(podUuid);

		// Just set the Pod to 'undefined' when the pod id is empty.
		if (!podUuid) {
			this.podService.podFromPath$.next(undefined);
			return false;
		}

		// Download the pod info.
		const observe = this.podService.pod(podUuid);

		// Check if we have access to the realm.
		// If not, navigate to /realms
		return observe.pipe(map((pod) => {
			if (pod === undefined) {
				return this.router.createUrlTree(['/realms']);
			} else {
				// Update the selected realm.
				this.podService.podFromPath$.next(pod);

				// Allow access
				return true;
			}
		}), catchError((err, obj) => {
			this.router.navigate(['/realms']);
			return of(false);
		}));
	 }
}
