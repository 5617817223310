import { Component } from '@angular/core';

@Component({
	selector: 'app-success-block',
	templateUrl: './success-block.component.html',
	styleUrls: ['./success-block.component.scss']
})
export class SuccessBlockComponent {

	constructor() {
	}

}
