import { Component } from '@angular/core';
import { PodService, PodItem, PodType } from '../pod.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, empty } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from '../../message_box/message_box.component';

@Component({
	selector: 'app-storage',
	templateUrl: './storage.component.html',
	styleUrls: ['./storage.component.scss']
})
export class StorageComponent {

	public pods$: Observable<Array<PodItem>>;
	public colCount: number;

	constructor(
		podService: PodService,
		private breakpointObserver: BreakpointObserver,
		private dialog: MatDialog,
	) {
		// Calculate the best number of columns.
		this.breakpointObserver.observe(Breakpoints.Handset)
		.subscribe(result => {
			this.colCount = result.matches ? 1 : 2;
		});

		// Filter active storage pods.
		try {
			this.pods$ = podService.pods()
			.pipe(map(arr => arr.filter(podItem =>
				podItem.type === PodType.Storage
				&& podItem.public_key)));
		} catch (e) {
			this.pods$ = empty();
		}
	}

	addStorageClicked() {
		const dialogRef = this.dialog.open(MessageBoxComponent, {
			data: {
				title: 'Add new storage',
				message: 'Adding new storage is not yet available.',
				isError: true
			}
		});
	}
}
