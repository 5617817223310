import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginTotpComponent } from '../login-totp/login-totp.component';
import { BehaviorSubject } from 'rxjs';

// The login form.
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	public loginForm = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl()
	});

	public totpToken: string | undefined = undefined;

	public loginError: string | undefined = undefined;

	readonly isSubmitting$ = new BehaviorSubject<boolean>(false);

	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog) {
	}

	public openTotpDialog(previousError: string | undefined) {
		const dialogRef = this.dialog.open(
			LoginTotpComponent,
			{
				width: '450px',
				data: {
					totpError: previousError,
				},
			},
		);
		dialogRef.afterClosed().subscribe(result => {
			if (result && result.submitted) {
				this.totpToken = result.token;
				this.onSubmit();
			} else {
				this.totpToken = undefined;
			}
		});
	}

	async onSubmit() {
		this.loginError = undefined;
		const email = this.loginForm.get('email').value;
		const pass = this.loginForm.get('password').value;

		this.isSubmitting$.next(true);

		try {
			if (await this.authService.login(email, pass, this.totpToken)) {
				let route: string;
				const par: Params = {};
				Object.entries(this.route.snapshot.queryParams).forEach(
					([key, value]) => {
						if (key === 'route') {
							route = value;
						} else {
							par[key] = value;
						}
					}
				);
				this.router.navigate([route ? route : '/'], {queryParams: par});
			} else {
				this.loginError = 'Invalid username or password.';
			}
		} catch (e) {
			if (e.status === 504) {
				this.loginError = 'Unable to connect to storro.com.';
			} else if (e.status === 403) {
				if (this.totpToken) {
					this.openTotpDialog(e.error);
				} else {
					this.openTotpDialog(undefined);
				}
			} else {
				this.loginError = 'Something went wrong when trying to log in.';
			}
		} finally {
			this.isSubmitting$.next(false);
		}
	}

	routeToSignup() {
		/// @todo token parameters.
		this.router.navigate(['/signup'], {queryParams: this.route.snapshot.queryParams});
	}

	routeToForgotPassword() {
		this.router.navigate(['/forgot_password']);
	}

	// convenience getters for easy access to form fields
	get email() {
		return this.loginForm.get('email');
	}

	get password() {
		return this.loginForm.get('password');
	}
}
