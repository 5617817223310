import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RealmService, RealmDetails } from './realm.service';
import { map, catchError } from 'rxjs/operators';

/** Guard routes that require a selected realm. */
@Injectable({
	providedIn: 'root'
})
export class RealmGuard implements CanActivate {

	constructor(
		private realmService: RealmService,
		private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
			: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		const realmIdFromPath = this.realmService.realmIdFromRoute(next);

		if (this.realmService.realmIdFromPath$.value === realmIdFromPath) {
			// We didn't change the realm. We're good to go. But, it might be
			// that the pod is not yet full constructed. Check if we know the
			// realm pod public key. If not, re-fetch it.
			if (!this.realmService.realmFromPath$.value.pod) {
				return this.realmService.realm(realmIdFromPath).pipe(map((realm) => {
					this.realmService.realmFromPath$.next(realm);
					return true;
				}));
			}
			return true;
		}

		// The current realm is changed:

		// Set the new realm id.
		this.realmService.realmIdFromPath$.next(realmIdFromPath);

		// Download the realm info.
		const observe = this.realmService.realm(realmIdFromPath);

		// Check if we have access to the realm.
		// If not, navigate to /realms
		return observe.pipe(map((realm) => {
			if (realm === undefined) {
				return this.router.createUrlTree(['/realms']);
			} else {
				// Update the selected realm.
				this.realmService.realmFromPath$.next(realm);

				if (!realm.pod || realm.psp.length === 0) {
					// The realm isn't fully deployed yet.
					// We require at least one realm pod and one psp pod.
					// Lets redirect to the bootstrapping page.
					this.router.navigate(
						['/realms', realmIdFromPath, 'bootstrap']);
				}

				// Allow access
				return true;
			}
		}), catchError((err, obj) => {
			this.router.navigate(['/realms']);
			return of(false);
		}));
	 }
}
