import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { AdminListItem, AdminsService } from '../admins.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, empty } from 'rxjs';

// List all admins. (Super Admin only)

@Component({
	selector: 'app-admin-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class AdminListComponent implements OnInit {

	public adminsDataSource: AdminDataSource;
	constructor(private adminService: AdminsService) {
	}

	ngOnInit() {
		this.adminsDataSource = new AdminDataSource(this.adminService.admins());
	}
}

/**
 * A table data source to display admins.
 */
export class AdminDataSource extends DataSource<AdminListItem> {

	// Columns to display
	columns = ['name', 'email', 'company'];

	constructor(private admins: Observable<Array<AdminListItem>>) {
		super();
	}

	connect(collectionViewer: CollectionViewer): Observable<Array<AdminListItem>> {
		try {
			return this.admins;
		} catch (e) {
			return empty();
		}
	}

	disconnect(collectionViewer: CollectionViewer) {}
}
