import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealmService, EventLogs, EventLogFrame, UserDetails, Device } from '../../realm.service';
import { UserEventFramesComponent } from './user_event_frames/event_frames.component';


// A list of all known peers from a pod. Clicking a list item
// shows details about the peer.

@Component({
	selector: 'app-event-logs',
	templateUrl: './event_logs.component.html',
	styleUrls: ['./event_logs.component.scss']
})
export class UserEventLogsComponent implements OnInit {

	public eventLogs: EventLogs;
	public columns = ['timestamp', 'category', 'severity', 'frames'];

	private user: UserDetails;
	private device: Device;

	constructor(
		private realmService: RealmService,
		private route: ActivatedRoute,
		private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: any
	)
	{
		this.user = data.user;
		this.device = data.device;
	}

	ngOnInit() {
		if (this.realmService.realmIdFromPath$.value) {
			this.realmService.userEventLogs(this.user.id, this.device.peer_key).subscribe(response => this.eventLogs = response);
		}
	}

	localize(date: string) {
		const d = new Date(date);
		return d.toLocaleString('en-GB');
	}

	openFrames(frames: Array<EventLogFrame>) {
		const dialogRef = this.dialog.open(UserEventFramesComponent, {
			data: {
				frames: frames
			},
			autoFocus: false
		});
	}
}
