import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PodService, DownloadDetails } from '../../pod.service';
import { Observable, timer, BehaviorSubject, Subscription } from 'rxjs';
import { NgBytesPipeModule } from 'angular-pipes';

@Component({
	selector: 'app-project-downloads',
	templateUrl: './downloads.component.html',
	styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit, OnDestroy {

	@Input() projectUuid: string;

	downloadDetails$: BehaviorSubject<DownloadDetails> = new BehaviorSubject<DownloadDetails>(undefined);
	private timerSubscription: Subscription;

	constructor(private podService: PodService) { }
	ngOnInit() {
		this.timerSubscription = timer(0, 2000).subscribe( async () => {
			this.downloadDetails$.next(
				await this.podService.projectDownloads(this.projectUuid).toPromise()
			);
		});
	}

	ngOnDestroy() {
		this.timerSubscription.unsubscribe();
	 }
}
