import { Component, Input } from '@angular/core';

// An online offline indicator for a device of a user.
@Component({
	selector: 'app-device-indicator',
	templateUrl: './device-indicator.component.html',
	styleUrls: ['./device-indicator.component.scss']
})
export class DeviceIndicatorComponent {

	@Input() networkState: string;
	constructor() { }
}
