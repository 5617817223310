import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SinglePageWrapperService {
	readonly backRoute$ = new BehaviorSubject<string>('');

	constructor() {
	}

	setBackRoute(route: string) {
		this.backRoute$.next(route);
	}
}
