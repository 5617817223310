import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-verify-email',
	templateUrl: './verify-email.component.html',
	styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

	// The token that is part of the email url.
	private token: string;
	public success = false;
	public loading = true;

	constructor(
		private authService: AuthService,
		private route: ActivatedRoute,
	) {
		// Get the token from the url.
		this.token = this.route.snapshot.queryParamMap.get('secret');
	}

	async ngOnInit() {
		try {
			await this.authService.verifyEmail(this.token);
			this.success = true;
		} catch (err) {
			this.success = false;
		}
		this.loading = false;
	}
}
