import { Component, OnInit } from '@angular/core';
import { PodService, PodDetails, PeerAddress, ProjectList, StorageUsed } from '../pod.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
	selector: 'app-storage-pod',
	templateUrl: './storage-pod.component.html',
	styleUrls: ['./storage-pod.component.scss']
})
export class StoragePodComponent implements OnInit {

	public pod$: Observable<PodDetails|undefined>;
	public podPeerNetworkPorts$: Observable<Array<number>>;
	public projectUuids$: Observable<ProjectList>;
	public contentStorageUsed$: Observable<StorageUsed>;

	constructor(
		private podService: PodService,
		public notificationService: NotificationService,
	) {
	}

	ngOnInit() {
		this.pod$ = this.podService.podFromPath$;

		if (this.podService.podFromPath$.value) {
			this.podPeerNetworkPorts$ = this.podService.peerNetworkAddress(
				this.podService.podFromPath$.value.public_key)
				.pipe(map((addresses: PeerAddress) => {
					const ports = new Array<number>();
					// Add the fixed 443 https port.
					ports.push(443);

					// Get all ports from the peer certificates addresses.
					for (const addr of addresses.addresses) {
						if (addr.startsWith('ssl')) {
							const port = addr.substr(addr.lastIndexOf(':') + 1);
							ports.push(+port);
						}
					}
					return ports;
				}));
		}

		if (this.podService.podIdFromPath$.value) {
			this.projectUuids$ = this.podService.projects();
			this.contentStorageUsed$ = this.podService.contentStorageUsed();
		}
	}

	public copyToClipboard(item: string) {
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', item);
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
		this.notificationService.open('Text is copied to clipboard');
	 }
}
