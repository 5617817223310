import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * A guard that checks if we are not logged-in.
 * Redirects to the / if we are logged-in.
 */
@Injectable({
	providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

	constructor(
		public auth: AuthService,
		public router: Router,
	) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// We have a token set, return false and redirect to the root page
		if (this.auth.bearerToken()) {
			this.router.navigate(['/']);
			return of(false);
		}

		return of(true);
	}
}
