import { Component, OnInit } from '@angular/core';
import { RealmService } from '../realm.service';
import { PodService } from '../../pods/pod.service';
import { Deploy, DeployStage } from './deploy';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

// A page for creating the initial Realm and Psp pod.

@Component({
	selector: 'app-bootstrap',
	templateUrl: './bootstrap.component.html',
	styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent implements OnInit {

	realmName: string;
	deployment: Deploy;

	// For using the enum in html.
	DeployStage = DeployStage;

	constructor(
		realmService: RealmService,
		podService: PodService,
		private router: Router,
		private route: ActivatedRoute,
		authService: AuthService,
	) {
		const realm = realmService.realmFromPath$.value;
		this.realmName = realm ? realm.name : '';
		const realmIsCreated = !realm || realm.pod != null;
		const storageIsCreated = !realm || realm.psp.length > 0;
		this.deployment = new Deploy(realmIsCreated, storageIsCreated, podService, () => {
			// This part is a temporary solution to send an invite for the admin after the Realm has been created
			// In an upcoming version this should removed here as we mark an user as admin
			// instead of having 2 users (an admin user and a normal user)

			// To create the user we should have all data of the current created Realm
			// So we first fetch the created Realm and update the observable that holds the realm data
			realmService.realm(realm.id).subscribe(async (realmDetails) => {
				realmService.realmFromPath$.next(realmDetails);
				const loggedInUser = authService.loggedInUser$.value;

				if(loggedInUser) {
					await realmService.createUser(
						`${loggedInUser.profile.firstName} ${loggedInUser.profile.lastName}`,
						loggedInUser.profile.email,
						loggedInUser.profile.company,
						'',
					);
				}
			});
		});
	}

	ngOnInit() {
		this.deployment.start();
	}

	done() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}
}
