import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable } from 'rxjs';

export interface BinariesInfo {
	name: string;
	url: string;
	version: string;
}

export interface BinaryUrls {
	linux: string;
	windows_x64: string;
	windows_x86: string;
	osx: string;
}

// A publicly available page that shows a binary download page for a realm.
@Component({
	selector: 'app-binaries',
	templateUrl: './binaries.component.html',
	styleUrls: ['./binaries.component.scss']
})
export class BinariesComponent implements OnInit {

	public $binariesInfo: Observable<BinariesInfo>;
	public deviceInfo: DeviceInfo;
	public $binaryUrls: Observable<BinaryUrls>;

	// Show all Platforms instead of just your own.
	public showAll = false;

	// Is set once a download choice is made.
	public selectedPlatform = '';

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		deviceService: DeviceDetectorService) {
			this.deviceInfo = deviceService.getDeviceInfo();
	}

	async ngOnInit() {
		const realmUuid = this.realmUuidFromRoute();
		if (!realmUuid) {
			throw Error('No realm selected.');
		}

		this.$binariesInfo = this.http.get<BinariesInfo>(
			`/api/realms/binaries/${realmUuid}`);
		this.$binariesInfo.subscribe((data: BinariesInfo) => {
				this.$binaryUrls = this.http.get<BinaryUrls>(data.url);
			}, error => {
				console.error(error);
			}
		);
	}

	private realmUuidFromRoute(): string|undefined {
		if (this.route.snapshot.paramMap.has('realmUuid')) {
			return this.route.snapshot.paramMap.get('realmUuid');
		} else {
			return undefined;
		}
	}

}
