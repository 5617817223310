import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { RealmService } from '../realm/realm.service';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot } from '@angular/router';
import { KeyValuePipe } from '@angular/common';

// Data format array item of /api/realms/{id}/pods
export interface PodItem {
	name: string;
	title: string;
	type: string;
	hostname: string;
	public_key: string;
}

export interface PeerKey {
	peer_key: string;
}

export interface PeerKeyList {
	peers: Array<string>;
}

export enum ConnectionState {
	Connecting = 'Connecting',
	Connected = 'Connected',
	Disconnected = 'Disconnected'
}

export interface PeerConnectionState {
	state: string;
}

export interface PeerAddress {
	addresses: Array<string>;
	protocols: Array<string>;
}

export interface PodDetails {
	name: string;
	type: string;
	hostname: string;
	public_key: string;
	command_server_enabled: boolean;
}

export interface PodAbout {
	version: string;
	storro_git_hash: string;
	zooid_git_hash: string;
	compile_timestamp: string;
}

export interface LicenseInfo {
	is_expired: boolean;
	expiration_date: Date;
}

export interface PodLicenseInfo {
	license_info: Array<LicenseInfo>;
}

export interface ProjectListItem {
	uuid: string;
	state: string;
	content_size: number;
	name: string;
	last_changed: Date;
}

export interface ProjectList {
	projects: Array<ProjectListItem>;
}

export interface ProjectDetails {
	name: string;
	on_disk_size: number;
	content_size: number;
	timestamp: Date;
	state: string;
	head_versions: Array<string>;
}

export enum PodType {
	Realm = 'realm',
	Storage = 'storage_peer'
}

export interface ProjectDetails {
	head_versions: Array<string>;
	name: string;
	size: number;
	state: string;
	timestamp: Date;
}

export interface DownloadDetailItem {
	name: string;
	type: string;
	progress: string;
	started: boolean;
	peer_pool: Array<string>;
	downloading_from: Array<string>;
}

export interface DownloadDetails {
	speed: number;
	downloads: Array<DownloadDetailItem>;
}

export interface StorageUsed {
	storage_used: number;
}
@Injectable({
	providedIn: 'root'
})
export class PodService {

	podIdFromPath$: BehaviorSubject<string|undefined> =
		new BehaviorSubject<string|undefined>(undefined);
	podFromPath$: BehaviorSubject<PodDetails|undefined> =
		new BehaviorSubject<PodDetails|undefined>(undefined);

	constructor(
		private http: HttpClient,
		private realmService: RealmService) { }

	public createPod(podType: PodType): Observable<PodItem> {
		return this.http.post<PodItem>(
			`/api/realms/${this.realmService.realmFromPathId()}/pods`,
				{type: podType});
	}

	// Return all Pods of the given realm
	public pods(): Observable<Array<PodItem>> {
		return this.http.get<Array<PodItem>>(`/api/realms/${this.realmService.realmFromPathId()}/pods`);
	}

	public pod(podName: string|undefined): Observable<PodDetails | undefined> {
		if (podName) {
			return this.http.get<PodDetails>(
				`/api/realms/${this.realmService.realmFromPathId()}/pods/${podName}`);
		} else {
			return undefined;
		}
	}

	public podAvailable(podName: string): Observable<boolean> {
		return this.http.get<PeerKey>(`/api/realms/${this.realmService.realmFromPathId()}/pods/${podName}/command/network/peer_key`)
		.pipe(map((peerKey) => {
			return true;
		}), catchError((err, obj) => {
			return of(false);
		}));
	}

	public podNameFromRoute(route: ActivatedRouteSnapshot): string|undefined {
		if (route.paramMap.has('podId')) {
			return route.paramMap.get('podId');
		} else {
			return undefined;
		}
	}

	// List all peer keys from the current pod.
	public peers(stateQuery?: ConnectionState): Observable<PeerKeyList> {

		let url = `/api/realms/${this.realmService.realmFromPathId()}`;
		url += `/pods/${this.podIdFromPath$.value}/command/network/peers`;
		if (stateQuery) {
			// The state query expects a non-capitalized state.
			url += '?state=' + stateQuery.toLocaleLowerCase();
		}
		return this.http.get<PeerKeyList>(url);
	}

	// Get the network state of a given peerKey from the current Pod.
	public peerNetworkState(peerKey: string): Observable<PeerConnectionState> {
		return this.http.get<PeerConnectionState>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/network` +
			`/peers/${peerKey}/state`);
	}

	// Get the network addresses of a given peerKey from the current Pod.
	public peerNetworkAddress(peerKey: string): Observable<PeerAddress> {
		return this.http.get<PeerAddress>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/network` +
			`/peers/${peerKey}`);
	}

	public consoleLogs(): Observable<string> {
		return this.http.get<string>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/logs`);
	}

	// Returns binary info of the executable
	public about(): Observable<PodAbout> {
		return this.http.get<PodAbout>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/about`);
	}

	// Returns the license info of the psp/realm.
	public licenseInfo(): Observable<PodLicenseInfo> {
		return this.http.get<PodLicenseInfo>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/license_info`);
	}

	// Restart a pod in the cluster
	public async restartPod() {
		this.http.post(`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/restart`, null).toPromise();
	}

	// Get all projects from the current Pod
	public projects(): Observable<ProjectList> {
		return this.http.get<ProjectList>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/projects`);
	}

	// Get some high level details about the project.
	public projectDetails(projectId: string): Observable<ProjectDetails> {
		return this.http.get<ProjectDetails>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/projects` +
			`/${projectId}`);
	}

	// Get the current download process of a project.
	public projectDownloads(projectId: string): Observable<DownloadDetails> {
		return this.http.get<DownloadDetails>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${this.podIdFromPath$.value}/command/projects` +
			`/${projectId}/downloads`);
	}

	// Return the total used content storage size from all project.
	public contentStorageUsed(): Observable<StorageUsed> {
		return this.contentStorageUsedByPodId(this.podIdFromPath$.value);
	}

	// Return the total used content storage size from all project.
	public contentStorageUsedByPodId(podId: string): Observable<StorageUsed> {
		return this.http.get<StorageUsed>(
			`/api/realms/${this.realmService.realmFromPathId()}` +
			`/pods/${podId}/command/storage`);
	}
}
