import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

// Specific administrator roles that can be passed to the AuthGuard
// using the data field of the router.
// I.e.: `data: {roles: [Roles.SuperAdmin]}}`
export enum Roles {
	SuperAdmin
}

/**
 * A guard that checks if we are logged in.
 * Redirects to the login page when we're not.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(public auth: AuthService, public router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
			: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		// We don't have a token set. Just return false.
		if (!this.auth.bearerToken()) {
			this.router.navigate(['/login'], {queryParams: next.queryParams});
			return false;
		}

		// Check if we have access our user account.
		// If not, we're not logged in.
		return this.auth.account.pipe(map((loggedInAdmin) => {
			const roles: Array<Roles>|undefined = next.data.roles;

			// Set the logged in user.
			this.auth.loggedInUser$.next(loggedInAdmin);

			if (roles && roles.includes(Roles.SuperAdmin)) {
				if (!loggedInAdmin.profile.isSuperAdmin) {
					this.router.navigate(['/403']);
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		}, () => {
			this.router.navigate(['/login'], {queryParams: next.queryParams});
			return false;
		}),
		catchError((err, obj) => {
			this.auth.loggedInUser$.next(undefined);
			this.router.navigate(['/login'], {queryParams: next.queryParams});
			return of(false);
		}));
	}
}
