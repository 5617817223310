import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RealmService, UserCount } from '../../realm.service';

@Component({
	selector: 'app-users-stats',
	templateUrl: './stats.component.html',
	styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

	data: any[];
	view: any[] = [250, 250];
	colorScheme = {
		domain: ['#2196F3', 'lightgray']
	};

	public userCount$: Observable<UserCount>;

	constructor(private realmService: RealmService) {
	}

	ngOnInit(): void {
		try {
			this.userCount$ = this.realmService.userCountStatistics();
			this.userCount$.subscribe(usrCount => {
				this.data = [
					{
						name: 'User count',
						value: usrCount.user_count
					}, {
						name: 'Available',
						value: usrCount.max_users - usrCount.user_count
					}
				];
				Object.assign(this, this.data);
			});
		} catch (err) {
		}
	}
}
