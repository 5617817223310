import { Component, OnInit } from '@angular/core';
import { Observable, empty, of } from 'rxjs';
import { RealmService, RealmAdminInvitationItem } from '../realm.service';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { NotificationService } from '../../shared/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { InviteAdminComponent } from '../invite-admin/invite-admin.component';
import { AdminListItem } from '../../admins/admins.service';
import { AdminDataSource } from '../../admins/list/list.component';
import { AuthService } from '../../auth/auth.service';

/**
 * A Component for displaying all Realm administrators.
 */
@Component({
	selector: 'app-admins',
	templateUrl: './admins.component.html',
	styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {

	public adminsDataSource: AdminDataSource;
	public invitationsDataSource: AdminInvitationDataSource;

	constructor(
		private realmService: RealmService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		private authService: AuthService,
	) { }

	ngOnInit() {
		try {
			this.adminsDataSource = new AdminDataSource(this.realmService.admins());
			this.invitationsDataSource = new AdminInvitationDataSource(this.realmService.adminInvitations());
		} catch(err) {
			/// @todo Show an error message.
			this.invitationsDataSource = new AdminInvitationDataSource(of(null));
			this.adminsDataSource = new AdminDataSource(of(null));
		}
	}

	async removeInvitation(inv: InvitationTableItem) {
		await this.realmService.removeAdminInvitation(inv.id);
		this.notificationService.open(`Invitation to ${inv.emailAddress} is removed`);
		this.ngOnInit();
	}

	removeAdmin(admin: AdminListItem) {
		this.authService.removeAdmin(admin.id).subscribe(
			() => this.adminsDataSource = new AdminDataSource(this.realmService.admins()),
			(error) => this.notificationService.open(`Cannot delete this admin because: "${error.error}"`),
			);
	}

	// Show the invitation dialog.
	openDialogInviteAdmin() {
		const dialogRef = this.dialog.open(InviteAdminComponent);
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
		});
	}
}

/**
 * Custom table item for the invitation table.
 */
export class InvitationTableItem {

	id: number;
	emailAddress: string;
	created: Date;
	state: string;

	constructor(base: RealmAdminInvitationItem) {
		this.id = base.id;
		this.emailAddress = base.email_invitee;
		this.created = base.created;

		const currentDate = new Date(Date.now());
		if (base.accepted_by) {
			this.state = `Accepted ${moment(base.accepted_on).fromNow()}`;
		} else if (base.expires > currentDate) {
			this.state = `Expired ${moment(base.expires).toNow()}`;
		} else {
			this.state = `Pending, expires ${moment(base.expires).fromNow()}`;
		}
	}
}

/**
 * A table data source to display the invited admins.
 */
export class AdminInvitationDataSource extends DataSource<InvitationTableItem> {

	// Columns to display
	columns: Array<string> = ['emailAddress', 'state', 'created'];

	constructor(private invitations: Observable<Array<RealmAdminInvitationItem>>) {
		super();
	}

	connect(collectionViewer: CollectionViewer)
			: Observable<Array<InvitationTableItem>> {
		try {
			return this.invitations
				.pipe(map((items: RealmAdminInvitationItem[]) =>
					items.map(item => new InvitationTableItem(item))));
		} catch (e) {
			return empty();
		}
	}

	disconnect(collectionViewer: CollectionViewer) {}
}
